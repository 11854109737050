import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { getTimeUntilFirstRace } from '../shared/gameUtils';

function CountdownTimer ({
  game,
}) {
  const [timeLeft, setTimeLeft] = useState(getTimeUntilFirstRace(game));

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(getTimeUntilFirstRace(game));
    }, 1000);

    return function cleanup () {
      clearTimeout(timer);
    };
  });

  const gameDaysUntilStart = moment.duration(timeLeft).days();
  if (gameDaysUntilStart) {
    return (
      <span>
        {gameDaysUntilStart} DAY{gameDaysUntilStart > 1 && 'S'}
      </span>
    );
  } else {
    return (
      <span>
        {timeLeft ? moment.utc(timeLeft).format('H:mm:ss') : ''}
      </span>
    );
  }
}

export default CountdownTimer;
