import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Redirect } from 'react-router';
import { auth0AuthenticationCallback } from '../../redux/actions/Auth0';

const AuthCallback = () => {
  const dispatch = useDispatch();
  const authenticated = useSelector((state) => state.account.authenticated);

  useEffect(() => {
    dispatch(auth0AuthenticationCallback());
  }, [dispatch]);

  if (authenticated) {
    return <Redirect to="/lobby" />;
  }

  return (<div className="App-content">Loading ....</div>);
};
export default AuthCallback;
