import React, { useState } from 'react';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import classNames from 'classnames/bind';
import classes from './MyStablesHorseListRow.module.scss';
import HorseDetailsModal from './modals/HorseDetailsModal';
import SpeedValueRiskModal from './modals/SpeedValueRiskModal';

const MyStablesHorseListRow = ({ runner, isFuture, game }) => {
  const [modalDetailShow, setModalDetailShow] = useState(false);
  const [modalInfoShow, setModalInfoShow] = useState(false);

  const isScratched = _.get(runner, 'scratched') || _.get(runner, 'disqualified');

  let finalPoints = <p className={classes.finalPoints}>--</p>;
  if (runner) {
    if (runner.score < 0) {
      finalPoints = <p className={classNames(classes.finalPoints, classes.negative)}>{runner.score}</p>;
    } else if (runner.score > 0) {
      finalPoints = <p className={classNames(classes.finalPoints, classes.positive)}>{runner.score}</p>;
    }
  }

  const bindClasses = classNames.bind(classes);
  const selectClasses = bindClasses({
    scratched: isScratched,
  });

  const toggleInfo = () => {
    setModalInfoShow(!modalInfoShow);
    setModalDetailShow(!modalDetailShow);
  };

  const history = useHistory();
  const editStable = () => {
    const path = {
      pathname: '/stables/builder',
      state: {
        gameId: game.id,
        fromTracks: false,
      },
    };
    history.push(path);
  };

  return (
    <div className={classes.stableList}>
      {runner ?
        (<Row
          key={runner.id}
          className={classes.stableListRow}
          onClick={() => (!isScratched && setModalDetailShow(true)) ||
            (isScratched && isFuture && editStable())}
        >
          {isScratched && <div className={classes.scratchedOverlay}>SCRATCH</div>}
          <Col
            xs={2}
            className={classes.raceCardInfo}
          >
            <div className={classNames(
              classes.postPosition,
              classes['cloth_color_' + runner.post_position],
              selectClasses)}
            >
              <div className={classes.postText}>
                <div className={classes.postNumber}>
                  {runner.post_position}
                </div>
                {`R${runner.race_number}`}
              </div>
            </div>
          </Col>
          <Col
            xs={8}
            className={classNames(classes.displayName, selectClasses)}
          >
            <div className={classes.horseName}>
              {runner.name}
            </div>
          </Col>
          <Col
            xs={2}
          >
            {!isFuture && finalPoints}
            {isFuture && isScratched &&
              <div className={classes.selectionWrapper}>
                <div className={classNames(classes.selectionStatus, classes.notSelected)} />
              </div>}
          </Col>
        </Row>)
        :
        (<Row
          className={classes.stableListRow}
          onClick={() => editStable()}
        >
          <Col
            xs={2}
            className={classes.raceCardInfo}
          >
            <div className={classNames(classes.postPosition, classes['cloth_color_none'])}>
            </div>
          </Col>
          <Col
            xs={8}
            className={classes.displayName}
          >
            <div className={classes.selectName}>SELECT ANOTHER HORSE</div>
          </Col>
          <Col
            xs={2}
          >
          </Col>
        </Row>)
      }
      {modalDetailShow && <HorseDetailsModal
        show={modalDetailShow}
        onHide={() => setModalDetailShow(false)}
        toggle={toggleInfo}
        runnerId={runner.id}
      />}

      {modalInfoShow && <SpeedValueRiskModal
        show={modalInfoShow}
        onHide={toggleInfo}
      />}
    </div>
  );
};

export default MyStablesHorseListRow;
