export const FETCH_USER_STABLES = 'USER/FETCH_USER_STABLES';
export const FETCH_USER_STABLES_SUCCESS = 'USER/FETCH_USER_STABLES_SUCCESS';
export const FETCH_USER_STABLES_FAIL = 'USER/FETCH_USER_STABLES_FAIL';
export const CLEAR_USER_STABLES = 'USER/CLEAR_USER_STABLES';

export const fetchUserStables = (userId) => ({
  type: FETCH_USER_STABLES,
  userId,
});

export const fetchUserStablesSuccess = (data) => ({
  type: FETCH_USER_STABLES_SUCCESS,
  data,
});

export const fetchUserStablesFail = (error) => ({
  type: FETCH_USER_STABLES_FAIL,
  error,
});

export const clearUserStables = () => ({
  type: CLEAR_USER_STABLES,
});
