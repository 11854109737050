import React from 'react';
import RunnerListRow from './RunnerListRow';
import * as _ from 'lodash';

const MAX_RUNNERS = 10;

const RunnersList = ({
  userRunners,
  onUpdateRunners,
}) => {

  const blankEntries = MAX_RUNNERS - userRunners.length;

  return (
    <div>
      {userRunners.length >= 1 ?
        (_.map(userRunners, (runner) =>
          <RunnerListRow
            empty={false}
            runner={runner}
            post={runner.post_position}
            key={runner.id}
            userRunner={userRunners.filter((userRunner) => userRunner.id === runner.id)}
            updateRunners={onUpdateRunners}
          />,
        ))
        :
        ('')
      }
      {_.range(0, blankEntries).map((current) =>
        <RunnerListRow
          empty={true}
          key={current}
          userRunner={0}
        />)
      }
    </div>
  );
};

export default RunnersList;
