import React from 'react';
import { Modal } from 'react-bootstrap';
import classes from './RaceCardModal.module.scss';
import moment from 'moment';
import _ from 'lodash';

function RaceCardModal ({ show, onHide, races, gameName, gameDate }) {
  const displayDate = moment(gameDate).format('MM/DD/YY');

  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title className={classes.titleContainer} >
          {gameName}
          <div className={classes.subTitle} >
            RACE CARD | {displayDate}
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className={classes.bodyContainer}>
        {_.map(
          races,
          (race) => (
            <React.Fragment key={race.race_number} >
              <div className={classes.race}>Race {race.race_number}:</div>
              <div>
                <div className={classes.raceName}>
                  {race.race_type_description}
                </div>
                <div className={classes.raceInfo}>
                  {race.distance} | {race.surface === 'T' ? 'Turf' : 'Dirt'} {race.division && ` | ${race.division}`}
                </div>
              </div>
            </React.Fragment>
          ))}
      </Modal.Body>
    </Modal>
  );
}
export default RaceCardModal;
