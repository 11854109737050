import * as Actions from '../actions/stableBuilder';

export const initialState = {
  stable: null,
  loading: false,
  runnerUpdateError: null,
  enterGameError: null,
  editStableError: null,
  stableSubmittingError: null,
  deleteStableError: null,
  runnerUpdating: false,
  isLocallyEditing: false,
  submitting: false,
  deleting: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case Actions.ENTER_GAME:
      return {
        ...state,
        loading: true,
      };
    case Actions.ENTER_GAME_SUCCESS:
      return {
        ...state,
        loading: false,
        stable: action.stable,
        isLocallyEditing: false,
      };
    case Actions.ENTER_GAME_FAIL:
      return {
        ...state,
        loading: false,
        enterGameError: action.error,
      };
    case Actions.UPDATE_RUNNERS:
      return {
        ...state,
        runnerUpdating: true,
      };
    case Actions.UPDATE_RUNNERS_SUCCESS:
      return {
        ...state,
        runnerUpdating: false,
        stable: action.stable,
      };
    case Actions.UPDATE_RUNNERS_FAIL:
      return {
        ...state,
        runnerUpdating: false,
        runnerUpdateError: action.error,
      };
    case Actions.EDIT_STABLE:
      return {
        ...state,
        loading: true,
      };
    case Actions.EDIT_STABLE_SUCCESS:
      return {
        ...state,
        loading: false,
        stable: action.stable,
        isLocallyEditing: action.isLocallyEditing,
      };
    case Actions.EDIT_STABLE_FAIL:
      return {
        ...state,
        loading: false,
        editStableError: action.error,
      };
    case Actions.SUBMIT_STABLE:
      return {
        ...state,
        submitting: true,
      };
    case Actions.SUBMIT_STABLE_SUCCESS:
      return {
        ...state,
        submitting: false,
        stable: null,
      };
    case Actions.SUBMIT_STABLE_FAIL:
      return {
        ...state,
        submitting: false,
        stableSubmittingError: action.error,
      };
    case Actions.DELETE_STABLE:
      return {
        ...state,
        deleting: true,
      };
    case Actions.DELETE_STABLE_SUCCESS:
      return {
        ...state,
        stable: null,
        isLocallyEditing: false,
        deleting: false,
      };
    case Actions.DELETE_STABLE_FAIL:
      return {
        ...state,
        deleting: false,
        deleteStableError: action.error,
      };
    case Actions.CLEAR_STABLE_BUILDER_STATE:
      return initialState;
    default:
      return state;
  }
};
