import { put, call, takeLatest, select } from 'redux-saga/effects';
import _ from 'lodash';
import { getStablesByUserId } from '../../api/StablesApi';
import * as Actions from '../actions/userStables';
import { fetchGameByID } from '../actions/games';
import { getToken } from '../selectors/account';

export function* fetchUserStables ({ userId }) {
  try {
    const token = yield select(getToken);
    const response = yield call(getStablesByUserId, userId, token);
    const userStables = response.results;

    const stableWithUniqueGames = _.uniqBy(userStables, 'game');
    for (const stable of stableWithUniqueGames) {
      yield put(fetchGameByID(stable.game));
    }

    yield put(Actions.fetchUserStablesSuccess(userStables));
  } catch (error) {
    yield put(Actions.fetchUserStablesFail({ error: error.response.data } ));
  }
}

export default function* userStablesSaga () {
  yield takeLatest(Actions.FETCH_USER_STABLES, fetchUserStables);
}
