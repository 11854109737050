import * as _ from 'lodash';
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import cmsGetHtmlContent from '../../cmsApi/cmsApi';
import classes from './CMSPage.module.scss';

const CMSPage = () => {
  const pathTitleTable = [
    {
      path: '/howtoplay',
      title: 'How+to+play',
    },
    {
      path: '/rules',
      title: 'Rules+and+Scoring',
    },
    {
      path: '/about',
      title: 'About',
    },
    {
      path: '/termsofuse',
      title: 'Terms+of+Service',
    },
    {
      path: '/privacy',
      title: 'Privacy+Policy',
    }];

  const location = useLocation();
  const [content, setContent] = useState({
    title: '',
    body: '',
  });
  const [loading, setLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    const title = _.find(pathTitleTable, ['path', location.pathname]).title;
    const fetchCMScontent = async () => {
      setIsError(false);
      setLoading(true);
      try {
        const result = await cmsGetHtmlContent(title);
        if (result && result.data.length > 0) {
          setContent({
            title: result.data[0].attributes.title,
            body: result.data[0].attributes.body.value,
          });
        }
      } catch (error) {
        setIsError(true);
      }
      setLoading(false);
    };
    fetchCMScontent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <div className={classes.cmsContainer}>
      <div className={classes.cmsRow}>
        <div className={classes.cmsHeading}>
          {content.title}
        </div>
        <div className={classes.cmsBody}>
          {isError && <div>Something went wrong ...</div>}
          {loading ? <div>Loading ...</div> : (
            <div dangerouslySetInnerHTML={{ __html: content.body }} />)}
        </div>
      </div>
    </div>
  );
};

export default CMSPage;
