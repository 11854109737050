export const FETCH_TRACKS_DATA = 'TRACKS/FETCH_DATA';
export const FETCH_TRACKS_DATA_SUCCESS = 'TRACKS/FETCH_DATA_SUCCESS';
export const FETCH_TRACKS_DATA_FAIL = 'TRACKS/FETCH_DATA_FAIL';

export const fetchTracks = () => ({
  type: FETCH_TRACKS_DATA,
});

export const fetchTracksSuccess = (data) => ({
  type: FETCH_TRACKS_DATA_SUCCESS,
  data,
});

export const fetchTracksFail = (error) => ({
  type: FETCH_TRACKS_DATA_FAIL,
  error,
});
