import React from 'react';
import classNames from 'classnames/bind';
import classes from './RunningHorse.module.scss';

export const RunningHorse = ({currentPosition=6, finalPosition}) => {
  const c = classNames.bind(classes);
  
  return (
    <div 
      className={classNames(classes.horse, c({'animated': currentPosition < finalPosition}))} 
      style={{ marginLeft: currentPosition + '% ', transform: 'translateX(-90%)' }} 
    />
  );
};
