import React from 'react';
import _ from 'lodash';
import {
  Col,
  Row,
} from 'react-bootstrap';
import CountdownTimer from '../Countdown.js';
import classNames from 'classnames/bind';
import classes from './MyStableRow.module.scss';
import { LOBBY } from './MyStableTypes';

const MyStableRow = ({ stable, onStableSelection, selectedStableId, stableWidgetType }) => {
  const c = classNames.bind(classes);
  const colorClassName = (color) => c({
    whiteStableName: color === 'white',
    yellowStableName: color === 'yellow',
    redStableName: color === 'red',
    greenStableName: color === 'green',
  });

  return (
    <Row className={classNames(classes.stableRow,
      c({
        selected: stable.stableId === selectedStableId,
        lobby: stableWidgetType.type === LOBBY,
      }))}
    onClick={() => onStableSelection(stable)}>
      <Col className={classNames(classes.stableName, colorClassName(stable.stableStatus.color))}>
        {_.get(stable, 'game.name')}
      </Col>
      <Col className={classes.rank} lg={2}>
        {_.get(stable,'game.timeUntilStart') ?
          <CountdownTimer game={stable.game} /> :
          <span>{stable.stableStatus.text}</span>}
      </Col>
    </Row>
  );
};

export default MyStableRow;
