import React from 'react';

import classes from './Button.module.scss';

const Button = ({ children, styling = '', clickAction = () => { }, disabled = false }) => (
  <button className={`${classes.btn} ${classes[styling]}`} onClick={clickAction} disabled={disabled}>
    {children ||  'default text'}
  </button>
);

export default Button;
