import { put, takeLatest } from 'redux-saga/effects';
import { getRunnerById } from '../../api/RunnersApi';
import * as Actions from '../actions/runners';

export function* fetchRunnerById ({ id }) {
  try {
    const response = yield getRunnerById(id);
    yield put(Actions.fetchRunnerSuccess(response));
  } catch (error) {
    yield put(Actions.fetchRunnerFail(error.response.data));
  }
}

export default function* runnersSaga () {
  yield takeLatest(Actions.FETCH_RUNNER, fetchRunnerById);
}
