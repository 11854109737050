import React from 'react';
import {Dropdown} from 'react-bootstrap';
import classNames from 'classnames/bind';
import classes from './SortToggle.module.scss';

// eslint-disable-next-line react/display-name
const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <button
    ref={ref}
    onClick={(e) => {
      onClick(e);
    }}
  >
    {children}
  </button>
));

const SortDropdown = ({sortOptions, sortBy, setSortBy}) => {
  const c = classNames.bind(classes);
  
  return (
    <Dropdown className={classes.sortToggle}>
      <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
        {sortBy}
      </Dropdown.Toggle>

      <Dropdown.Menu className={classes.dropdownMenu} alignRight>
        {sortOptions.map((option) => (
          <Dropdown.Item
            key={option}
            className={classNames(classes.dropdownItem, c({active: option === sortBy}))}
            eventKey={option}
            active={option === sortBy}
            onSelect={(option)=>setSortBy(option)}>
            {option}
          </Dropdown.Item>
        ))}
      
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default SortDropdown;
