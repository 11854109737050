import { sdApiRequest } from './StableDuelApi.js';

export const getGames = () => {
  return sdApiRequest({
    method: 'GET',
    url: 'games/',
  });
};

export const getGamesByDateRange = (params) => {
  return sdApiRequest({
    method: 'GET',
    url: 'games/',
    data: params,
  });
};

export const getGameById = (id) => {
  return sdApiRequest({
    method: 'GET',
    url: `games/${id}/`,
  });
};
