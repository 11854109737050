import React from 'react';
import classes from './Loader.module.scss';
import Horse from '../assets/images/animated-horse.gif';

const Loader = () => (
  <div className={classes.loaderWrapper}>
    <img className={classes.loaderImage} src={Horse} alt={'Loading...'}/>
  </div>
);

export default Loader;
