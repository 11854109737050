import { put, takeLatest } from 'redux-saga/effects';
import * as Actions from '../actions/globalLeaderBoard';
import { fetchGlobalLeaderBoard } from '../../api/GlobalLeaderBoardApi';

export function* globalLeaderBoard ({page}) {
  try {
    const response = yield fetchGlobalLeaderBoard(page);
    yield put(Actions.fetchGlobalLeaderBoardSuccess(response));
  } catch (error) {
    yield put(Actions.fetchGlobalLeaderBoardFail(error.response.data));
  }
}

export default function* globalLeaderBoardSaga () {
  yield takeLatest(Actions.FETCH_GLOBAL_LEADER_BOARD, globalLeaderBoard);
}
