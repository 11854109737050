import React from 'react';
import { useSelector } from 'react-redux';
import {
  Col,
  Row,
} from 'react-bootstrap';

import classes from './ContentGrid.module.scss';
import TracksWidget from '../components/TrackWidget/TracksWidget';
import SDLogoWithTag from '../assets/images/stableduel-logo-with-tag.png';
import RecentNews from './RecentNews';
import StablePointsProgress from './StablePointsProgress';
import GlobalLeaderBoard from './globalLeaderBoard/GlobalLeaderBoard';
import MyStablesWidget from './myStables/MyStablesWidget';
import { groupStablesByTrack } from '../shared/stableUtils';
import { MyStableTypes } from './myStables/MyStableTypes';
import { getUserStablesWithGameAndRaceCard } from '../redux/selectors/userStables';

const ContentGrid = () => {
  const userStables = useSelector(getUserStablesWithGameAndRaceCard);
  const stablesByTrack = groupStablesByTrack(userStables);

  return (
    <div className={classes.contentGrid} >
      <Row>
        <Col className={classes.contentCell} lg='3'>
          <Row className="justify-content-lg-center">
            <MyStablesWidget
              stableWidgetType={MyStableTypes.Lobby}
              userStables={stablesByTrack}/>
          </Row>
          <Row className="justify-content-lg-center">
            <TracksWidget />
          </Row>
        </Col>
        <Col className={classes.contentCell}>
          <Row>
            <img
              className={classes.sdLogoTag}
              src={SDLogoWithTag}
              alt="Stable Duel: Play Race Win"
            />
          </Row>
          <RecentNews />
        </Col>
        <Col className={classes.contentCell} lg='4'>
          <Row className="mt-4">
            <StablePointsProgress />
          </Row>
          <Row>
            <GlobalLeaderBoard />
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default ContentGrid;
