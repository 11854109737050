import { put, call, select, takeLatest } from 'redux-saga/effects';
import toastr from 'toastr';
import {
  createStableForUser,
  updateStableForUser,
  deleteStableById } from '../../api/StablesApi';
import * as Actions from '../actions/stableBuilder';
import { getStable, isLocallyEditing } from '../selectors/stableBuilder';
import { getToken } from '../selectors/account';
import { userStablesByIdSelector } from '../selectors/userStables';

export function* enterGame ({ gameId, userId, history }) {
  try {
    const newStableParam = {
      user: userId,
      game: gameId,
      runners: [],
    };
    const token = yield select(getToken);
    const stable = yield call(createStableForUser, newStableParam, token);
    yield put(Actions.enterGameSuccess(stable));
    history.push(`/stables/builder/${stable.id}`);
  } catch (error) {
    yield put(Actions.enterGameFail(error));
    toastr.error('Enter Game Error');
    history.push('/tracks');
  }
}

export function* updateRunners ({ id, runners }) {
  try {
    let currentStable = yield select(getStable);
    if (!currentStable) {
      currentStable = yield select(userStablesByIdSelector, id);
    }
    let updatedStable = { ...currentStable };
    updatedStable.runners = runners;

    const isLocal = yield select(isLocallyEditing);
    if (!isLocal) {
      const token = yield select(getToken);
      updatedStable = yield call(updateStableForUser, id, updatedStable, token);
    }
    yield put(Actions.updateRunnersSuccess(updatedStable));
  } catch (error) {
    yield put(Actions.updateRunnersFail(error));
    toastr.error('Update Runners Error');
  }
}

export function* deleteStable ({ id, history }) {
  try {
    const token = yield select(getToken);
    yield call(deleteStableById, id, token);
    yield put(Actions.deleteStableSuccess(id));
    if (history.location.pathname !== '/stables') {
      history.push('/stables');
    }
  } catch (error) {
    yield put(Actions.deleteStableFail(error));
    toastr.error('Delete Stable Error');
  }
}

export function* submitStable ({ id, history }) {
  try {
    const stable = yield select(getStable);
    const token = yield select(getToken);
    const newStable = yield call(updateStableForUser, id, stable, token);
    yield put(Actions.submitStableSuccess(newStable));
    if (history) {
      history.push('/stables');
    }
  } catch (error) {
    yield put(Actions.submitStableFail(error));
    toastr.error('Submit Stable Error');
  }
}

export function* editStable ({ id, mode, history }) {
  try {
    let stable = yield select(getStable);
    if (!stable) {
      // TODO: Using selector will create a problem when reloading the edit page.
      // Edit page could be blank, when a user has many stables.
      // UserStables is paginated and only first page got loaded.
      // The stable that we want may be in a different page.
      // The right fix should be when we load user stables at the beginning.
      stable = yield select(userStablesByIdSelector, id);
    }
    yield put(Actions.editStableSuccess({
      stable,
      isLocallyEditing: mode === 'edit' ? true : false,
    }));
  } catch (error) {
    yield put(Actions.editStableFail(error));
    // When a user tries to edit a stable that does not exist,
    // redirect to My Stables page.
    history.block(null); // Not showing prompt
    history.push('/stables');
  }
}

export default function* stableSaga () {
  yield takeLatest(Actions.ENTER_GAME, enterGame);
  yield takeLatest(Actions.UPDATE_RUNNERS, updateRunners);
  yield takeLatest(Actions.EDIT_STABLE, editStable);
  yield takeLatest(Actions.SUBMIT_STABLE, submitStable);
  yield takeLatest(Actions.DELETE_STABLE, deleteStable);
}
