import React from 'react';

import classes from './Lobby.module.scss';
import ContentGrid from '../components/ContentGrid';

const Lobby = () => (
  <div className={classes.lobby}>
    <ContentGrid />
  </div>
);

export default Lobby;
