import React from 'react';
import { Row, Col } from 'react-bootstrap';
import classes from './RecentNews.module.scss';
import _ from 'lodash';
import Loader from './Loader';

//default array provides default iterable object to generate preview visual
const RecentNews = ({ newsObjects = [
  // This is a temporary set of objects until connected to api
  // Remove these and replace with empty array
  // TODO connect news APIs
  {
    title: 'A title',
    preview: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce tristique auctor auctor. ' 
    + 'Fusce ac dui vel eros malesuada consectetur nec.',
    imageUrl: 'https://static.americasbestracing.net/s3fs-public/styles/large_hero_16_9/public/article/warofwill' 
    + 'preaknessstretcheclipse.JPG?W9V7dE7sYm2GxvaeD9hLtzLub52lgRqq&itok=eOaD0okJ&c=cd787e0c8dbd7217e1c390dd8ddcf7b5',
    callToAction: 'http://www.google.com',
  },
  {
    title: 'A title',
    preview: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed venenatis dapibus ante non ullamcorper. '
    + 'In orci magna, egestas pharetra leo id, gravida consequat mauris. Nulla aliquet tortor in odio lacinia.',
    imageUrl: 'https://static.americasbestracing.net/s3fs-public/styles/large_hero_16_9/public/article/warofwill' + 
    'preaknessstretcheclipse.JPG?W9V7dE7sYm2GxvaeD9hLtzLub52lgRqq&itok=eOaD0okJ&c=cd787e0c8dbd7217e1c390dd8ddcf7b5',
    callToAction: 'http://www.google.com',
  },
  {
    title: 'A title',
    preview: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut et ante imperdiet, tincidunt massa id, '
    + 'pharetra eros. Quisque erat massa, gravida nec nunc eget, varius pellentesque nulla. Duis felis nisl.',
    imageUrl: 'https://static.americasbestracing.net/s3fs-public/styles/large_hero_16_9/public/article/warofwill' 
    + 'preaknessstretcheclipse.JPG?W9V7dE7sYm2GxvaeD9hLtzLub52lgRqq&itok=eOaD0okJ&c=cd787e0c8dbd7217e1c390dd8ddcf7b5',
    callToAction: 'http://www.google.com',
  } ,
] }) => {

  const NewsContent = ({ payload = {} }) => {
    const isLoader = _.isEmpty(payload);

    return (
      <div>
        {isLoader ?
          <Loader /> :
          <Row className={classes.newsWrapper}>
            <Col className={classes.newsImageCol} lg='3'>
              <img
                className={classes.newsImage}
                src={payload.imageUrl}
                alt="Recent News"
              />
            </Col>
            <Col className={classes.newsItem} lg='9'>
              <Row className={classes.newsTitle}>
                {payload.title}
              </Row>
              <Row className={classes.newsText}>
                {payload.preview}
              </Row>
              <Row className={classes.newsLink}>
                <a href={payload.callToAction} target="_blank" rel="noopener noreferrer">
                  Read More
                </a>
              </Row>
            </Col>
          </Row>
        }
      </div>
    );
  };

  return (
    <div className={classes.news}>
      <div className={classes.newsHeader}>
        Recent News
      </div>
      {newsObjects.map((payload, index) => <NewsContent key={`NewsItem${index}`} payload={payload} />)}
    </div>
  );
};

export default RecentNews;
