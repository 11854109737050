export const composeValidators = (...validators) => (value) =>
  validators.reduce((error, validator) => error || validator(value), undefined);

export const required = (value) => (value ? undefined : 'Required');

/* eslint-disable no-useless-escape  */
export const email = (value) => {
  const re = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

  return re.test(value.toLowerCase()) ? undefined : 'Valid email required';
};

export const maxLength = (max) => (value) =>
  value.length <= max ? undefined : `Length must be less than ${max}`;

export const minLength = (min) => (value) =>
  value.length >= min ? undefined : `Length must be greater than ${min}`;

// 1 upper, 1 lower, 1 number, 1 special character
export const passwordConditions = (value) => {
  if(value.length < 8){
    return false;
  }

  var rules = [
    /[a-z]/, //letters (lower-case)
    /[A-Z]/, //letters (upper-case)
    /\d/, //numbers (similar to /[0-9]/)
    /[!@#$&*]/, //some symbols
  ];

  return rules.filter(function (rule) {
    return rule.test(value);
  }).length >= 3 ? undefined : 'Password needs 3 of 4: upper, lower, number, special character';
};

export const pattern = (re, msg) => (value) => {
  return re.test(value) ? undefined : msg;
};

export const zipcode = (value) => {
  const re = /^(?!0{5})(\d{5})(?!-?0{4})(|-\d{4})?$/;

  return re.test(value) ? undefined : 'Valid zipcode required';
};
