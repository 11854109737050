import React from 'react';

import classes from './WelcomeScreen.module.scss';
import SignupForm from './forms/SignupForm';
import LinkBtn from './LinkBtn';
import Background from './Background';

export default function Signup () {
  return (
    <div className= {`${ classes.defaultBackground } container`} id="signup-page">
      <Background />
      <div className={classes.pagecontent}>
        <div className={ classes.logoWrapper } />
        <div className={ classes.formWrapper }>
          <SignupForm />
        </div>
        <div className={ classes.switchWrapper }>
          <div className= { classes.joinTxt }>Already have an account? Log in now.</div>
          <LinkBtn className={classes.joinBtn} label='Log In' path='/login' styling={'outline'} />
        </div>
      </div>
    </div>
  );
}
