import moment from 'moment';
import _ from 'lodash';

const setColorForTheTopWinner = (rank) => rank <= 3 ? 'green' : 'white';

export const getStableStatus = (stable) => {
  switch (stable.state) {
    case 'NOT_STARTED_VALID': {
      const scratchedRunners = _.filter((stable.runners), { 'scratched': true });
      if (scratchedRunners.length > 0) {
        return {
          text: 'Scratch',
          color: 'yellow',
        };
      } else {
        return {
          text: '',
          color: 'white',
        };
      }
    }
    case 'NOT_STARTED_INVALID': {
      return {
        text:'Fix Stable',
        color:'red',
      };
    }
    case 'STARTED_VALID': {
      const text = stable.rank ? moment.localeData().ordinal(stable.rank) : 'LIVE';
      const color = text === 'LIVE' ? 'white' : setColorForTheTopWinner(stable.rank);

      return { text, color };
    }
    case 'STARTED_NEEDS_SCRATCH_REPLACEMENT':
    case 'STARTED_IS_REPLACING_SCRATCH': {
      return {
        text: 'Scratch',
        color: 'yellow',
      };
    }
    case 'FINAL_RESULTS_PENDING': {
      const text = stable.rank ? moment.localeData().ordinal(stable.rank) : 'LIVE';
      const color = text === 'LIVE' ? 'white' : setColorForTheTopWinner(stable.rank);

      return { text, color };
    }
    case 'FINISHED': {
      const text = stable.rank ? moment.localeData().ordinal(stable.rank) : 'FINAL';
      const color = text === 'FINAL' ? 'white' : setColorForTheTopWinner(stable.rank);

      return { text, color };
    }
    case 'STARTED_DISQUALIFIED':
    default: {
      return {
        text: 'Invalid',
        color: 'red',
      };
    }
  }
};

export const groupStablesByTrack = (stableList) => {
  const sortedStableList = _.orderBy(stableList, ['game_obj.race_date'], ['desc']);

  return _.mapValues(
    _.groupBy(sortedStableList, 'game_obj.track.name'),
    (sbList) => {

      return _.map(sbList, (sb) => {
        const runnersInfo = _.map(sb.runners,
          (id) => _.find(_.get(sb, 'game_obj.racecard_obj.runners'),
            (info) => info.id === id));

        return {
          stableId: sb.id,
          stable: sb,
          game: sb.game_obj,
          runners: runnersInfo,
          stableStatus: getStableStatus(sb),
        };
      });
    });
};
