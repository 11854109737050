import * as auth0 from 'auth0-js';
import Cookies from 'universal-cookie';
import {
  all,
  call,
  put,
  take,
  cps,
  select,
} from 'redux-saga/effects';

import {
  AUTH0_LOGIN_REQUEST,
  AUTH0_LOGIN_SUCCESS,
  AUTH0_LOGIN_ERROR,
  AUTH0_SIGNUP_REQUEST,
  AUTH0_SIGNUP_SUCCESS,
  AUTH0_SIGNUP_ERROR,
  AUTH0_LOGOUT_REQUEST,
  AUTH0_LOGOUT_ERROR,
  AUTH0_CALLBACK_RESPONSE,
  AUTH0_CALLBACK_RESPONSE_ERROR,
  AUTH0_VERIFY_TOKEN_SUCCESS,
  AUTH0_VERIFY_TOKEN_ERROR,
  AUTH0_FORGOT_PASSWORD_REQUEST,
  AUTH0_FORGOT_PASSWORD_SUCCESS,
  AUTH0_FORGOT_PASSWORD_ERROR,
} from '../actions/Auth0';

import {
  LOAD_USER_SUCCESS,
} from '../actions/user';

import { loadUser } from '../sagas/user';
import { getUser } from '../selectors/user';
import { validateBirthday } from '../../api/UserApi';

const auth0Client = new auth0.WebAuth({
  domain: process.env.REACT_APP_AUTH0_DOMAIN,
  clientID: process.env.REACT_APP_AUTH0_CLIENT_ID,
  redirectUri: process.env.REACT_APP_REDIRECT_URI,
  responseType: 'token id_token',
  scope: 'openid profile email',
});

const idTokenName = process.env.REACT_APP_ID_TOKEN_NAME;
const accessTokenName = process.env.REACT_APP_ACCESS_TOKEN_NAME;
const tokenExpiration = process.env.REACT_APP_TOKEN_EXPIRATION;
const CONNECTION = 'Username-Password-Authentication';

export function* signup ({ formData }) {
  try {
    const params = {
      connection: CONNECTION,
      ...formData,
    };
    const response = yield cps([auth0Client, auth0Client.signup], params);
    yield put({ type: AUTH0_SIGNUP_SUCCESS, response, formData });
  } catch (error) {
    yield put({ type: AUTH0_SIGNUP_ERROR, error: error.description });
  }
}

export function* login ({ formData }) {
  try {
    const params = {
      realm: CONNECTION,
      username: formData.email,
      password: formData.password,
    };
    yield cps([auth0Client, auth0Client.login], params);
  } catch (error) {
    yield put({ type: AUTH0_LOGIN_ERROR, error: error.description });
  }
}

export function* logout () {
  try {
    // We have to remove cookies before logout.
    // After logout, Auth0 will return to the login page and
    // and if we still have tokens then we think that we login and redirect back to lobby.
    // TO DO: We should have the '/logout' route and clean up the cookies there.
    new Cookies().remove(idTokenName, { path: '/' });
    new Cookies().remove(accessTokenName, { path: '/' });
    new Cookies().remove(tokenExpiration, { path: '/' });
    yield cps([auth0Client, auth0Client.logout], {
      returnTo: process.env.REACT_APP_LOGOUT_RETURN_PATH,
      clientID: process.env.REACT_APP_AUTH0_CLIENT_ID,
    });
  } catch (error) {
    yield put({ type: AUTH0_LOGOUT_ERROR, error: error.description });
  }
}

export function* handleCallbackResponse () {
  try {
    const authResult = yield cps([auth0Client, auth0Client.parseHash]);
    const currentTime = new Date();
    const expirationDate = new Date(currentTime.getTime() + authResult.expiresIn * 1000).getTime();
    new Cookies().set(idTokenName, authResult.idToken, { path: '/' });
    new Cookies().set(accessTokenName, authResult.accessToken, { path: '/' });
    new Cookies().set(tokenExpiration, expirationDate, { path: '/' });
    yield put({
      type: AUTH0_LOGIN_SUCCESS,
      profile: authResult.idTokenPayload,
      idToken: authResult.idToken,
      accessToken: authResult.accessToken,
      expirationDate,
      authenticated: true,
    });
  } catch (error) {
    yield call(logout);
    yield put({ type: AUTH0_CALLBACK_RESPONSE_ERROR, error: error.message });
  }
}

export function* verifyToken () {
  try {
    const idToken = new Cookies().get(idTokenName);
    const accessToken = new Cookies().get(accessTokenName);
    const expirationDate = new Cookies().get(tokenExpiration);
    const currentTime = new Date();
    if (expirationDate >= currentTime) {
      yield put({
        type: AUTH0_VERIFY_TOKEN_SUCCESS,
        idToken,
        accessToken,
        expirationDate,
        authenticated: true,
      });
    } else {
      // TO DO: We should get new tokens when token expires. Use logout for now
      yield call(logout);
    }
  } catch (error) {
    yield put({ type: AUTH0_VERIFY_TOKEN_ERROR, error: error.message });
  }
}

export function* validateInput (action) {
  const { formData } = action;
  try {
    yield call(validateBirthday, formData.zip_code, formData.birthdate);
    yield call(signup, action);
  } catch (error) {
    yield put({ type: AUTH0_SIGNUP_ERROR, error: error.data.detail });
  }
}

export function* forgotPassword ({ formData }) {
  try {
    const params = {
      connection: CONNECTION,
      email: formData.email,
    };
    const response = yield cps([auth0Client, auth0Client.changePassword], params);
    yield put({
      type: AUTH0_FORGOT_PASSWORD_SUCCESS, message: response,
    });
  } catch (error) {
    yield put({ type: AUTH0_FORGOT_PASSWORD_ERROR, error: error.message });
  }
}

export function* loginFlow () {
  while (true) {
    const idToken = new Cookies().get(idTokenName);
    if (idToken) {
      yield call(verifyToken);
    }

    if (!(yield select(getUser))) {
      const action = yield take([
        AUTH0_LOGIN_REQUEST,
        AUTH0_SIGNUP_REQUEST,
        AUTH0_CALLBACK_RESPONSE,
        AUTH0_LOGIN_SUCCESS,
        LOAD_USER_SUCCESS,
        AUTH0_FORGOT_PASSWORD_REQUEST,
      ]);
      switch (action.type) {
        case AUTH0_LOGIN_REQUEST:
          yield call(login, action);
          break;
        case AUTH0_SIGNUP_REQUEST:
          yield call(validateInput, action);
          break;
        case AUTH0_CALLBACK_RESPONSE:
          yield call(handleCallbackResponse);
          break;
        case AUTH0_LOGIN_SUCCESS:
          yield call(loadUser);
          break;
        case AUTH0_FORGOT_PASSWORD_REQUEST:
          yield call(forgotPassword, action);
          break;
        case LOAD_USER_SUCCESS:
        default:
          break;
      }
    }

    if (yield select(getUser)) {
      yield take(AUTH0_LOGOUT_REQUEST);
      yield call(logout);
    }
  }
}

export default function* () {
  yield all([
    loginFlow(),
  ]);
}
