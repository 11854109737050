import { combineReducers } from 'redux';
import { default as tracksReducer } from './tracks';
import { default as gamesReducer } from './games';
import { default as auth0Reducer } from './Auth0';
import { default as userReducer } from './user';
import { default as userStablesReducer } from './userStables';
import { default as glbReducer } from './globalLeaderBoard';
import { default as runnersReducer } from './runners';
import { default as stableReducer } from './stableBuilder';
import { default as racecardReducer } from './racecards';
import { default as leaderBoardReducer } from './leaderBoard';

const rootReducer = combineReducers({

  tracks: tracksReducer,
  games: gamesReducer,
  racecards: racecardReducer,
  account: auth0Reducer,
  user: userReducer,
  stables: userStablesReducer,
  globalLeaderBoard: glbReducer,
  runners: runnersReducer,
  stableBuilder: stableReducer,
  leaderBoard: leaderBoardReducer,
});

export default rootReducer;
