import React from 'react';
import { Row, Col } from 'react-bootstrap';
import classes from './RaceCardHeader.module.scss';

const RaceCardHeader = () => (
  <Row className={classes.raceHeader} >
    <Col xs={{span: 2, order: 2}} lg={{span: 1, order: 1}} >#</Col>
    <Col className={classes.nameHeader} xs={{order: 1}} sm={5} lg={{span: 5, order: 2}} >Name</Col>
    <Col xs={{span: 5, order: 3}} md={{ span: 1, offset: 3}}  className={classes.salary}>Salary</Col>
  </Row>
);
export default RaceCardHeader;
