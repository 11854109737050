export const surfaceTypes = {
  T: 'Turf',
  G: 'Grass',
  S: 'Synthetic',
  M: 'Mixed',
  D: 'Dirt',
};

export const thousandsSeparator = (number) => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};
