import React from 'react';

import classes from './WelcomeScreen.module.scss';
import ForgotPasswordForm from '../components/forms/ForgotPasswordForm';
import Background from './Background';

export default function Login () {

  return (
    <div className={`${classes.defaultBackground} container`} id="login-page">
      <Background />
      <div className={classes.pagecontent}>
        <div className={classes.logoWrapper} />
        <div className={classes.formWrapper}>
          <ForgotPasswordForm />
        </div>
      </div>
    </div>
  );
}
