import * as Actions from '../actions/userStables';
import {
  ENTER_GAME_SUCCESS,
  UPDATE_RUNNERS_SUCCESS,
  DELETE_STABLE_SUCCESS,
  SUBMIT_STABLE_SUCCESS,
} from '../actions/stableBuilder';
import { responseArrayToObject } from './utils';

import * as _ from 'lodash';

const initialState = {
  data: null,
  loading: false,
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case Actions.FETCH_USER_STABLES:
      return {
        ...state,
        loading: true,
      };
    case Actions.FETCH_USER_STABLES_SUCCESS:
      return {
        ...state,
        data: _.merge({}, state.data, responseArrayToObject(action.data)),
        loading: false,
      };
    case Actions.FETCH_USER_STABLES_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case Actions.CLEAR_USER_STABLES:
      return {
        ...state,
        data: {},
      };
    case ENTER_GAME_SUCCESS:
      return {
        ...state,
        data: _.merge({}, state.data, { [action.stable.id]: { ...action.stable } }),
        loading: false,
      };
    case SUBMIT_STABLE_SUCCESS:
    case UPDATE_RUNNERS_SUCCESS:
      return {
        ...state,
        data: _.assign({}, state.data, { [action.stable.id]: { ...action.stable } }),
        loading: false,
      };
    case DELETE_STABLE_SUCCESS:
      return {
        ...state,
        data: _.omit(state.data, [action.id]),
        loading: false,
      };
    default: return state;
  }
};
