import TrophyNone from '../assets/images/lobby/trophy-none.png';
import TrophySilver from '../assets/images/lobby/trophy-silver-title.png';
import TrophyGold from '../assets/images/lobby/trophy-gold-title.png';
import TrophyPlatinum from '../assets/images/lobby/trophy-platinum-title.png';
import TrophyDiamond from '../assets/images/lobby/trophy-diamond-title.png';
import TrophyVip from '../assets/images/lobby/trophy-vip-purple-title.png';
import StatusSilver from '../assets/images/lobby/status-silver.png';
import StatusGold from '../assets/images/lobby/status-gold.png';
import StatusPlatinum from '../assets/images/lobby/status-platinum.png';
import StatusDiamond from '../assets/images/lobby/status-diamond.png';
import StatusVip from '../assets/images/lobby/status-vip-purple.png';

const tiers = [
  {
    src: TrophyNone,
    postSrc: '',
    threshhold: 0,
    progressWidth: 10,
    styleClass: '',
  },
  {
    src: TrophySilver,
    postSrc: StatusSilver,
    threshhold: 12500,
    progressWidth: 20,
    styleClass: 'silver',
  },
  {
    src: TrophyGold,
    postSrc: StatusGold,
    threshhold: 50000,
    progressWidth: 20,
    styleClass: 'gold',
  },
  {
    src: TrophyPlatinum,
    postSrc: StatusPlatinum,
    threshhold: 75000,
    progressWidth: 20,
    styleClass: 'platinum',

  },
  {
    src: TrophyDiamond,
    postSrc: StatusDiamond,
    threshhold: 250000,
    progressWidth: 20,
    styleClass: 'diamond',
  },
  {
    src: TrophyVip,
    postSrc: StatusVip,
    threshhold: 500000,
    progressWidth: 0,
    styleClass: 'vip',
  },
];

export const stablePointTiers = () => {
  return tiers;
};

export const findPlayerLevel = (stablePoints) => {
  const cloneTiers = [...tiers];
  const userTiers = cloneTiers.filter((tier) => stablePoints >= tier.threshhold);
  const userRank = userTiers.length;
  let playerTier = userTiers.pop();
  const nextTier = cloneTiers[Math.min(userRank, tiers.length - 1)];
  playerTier.stablePoints = stablePoints;
  playerTier.distanceToLevel = Math.floor((1 - (nextTier.threshhold - stablePoints) 
    / ((nextTier.threshhold - playerTier.threshhold) || 1)) * 100);
  playerTier.userProgressPct = userTiers.reduce((total, tier) => 
    total + tier.progressWidth, 0) + (playerTier.distanceToLevel / 100 * playerTier.progressWidth);
  
  return playerTier;
};
