import React from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames/bind';
import {
  Col,
  Row,
} from 'react-bootstrap';
import classes from './MyStablesWidget.module.scss';
import MyStablesImage from '../../assets/images/nav_mystables.png';
import Loader from '../Loader';
import { getUserStablesLoading } from '../../redux/selectors/userStables';
import MyStablesList from './MyStablesList';
import { TODAY } from './MyStableTypes';

const MyStablesWidget = ({
  stableWidgetType,
  userStables,
  updateSelectedStable = () => {},
  selectedStableId = 0,
  height = '' }) => {
  const userStablesLoading = useSelector(getUserStablesLoading);
  const gamesLoading = useSelector((state) => state.games.loading);
  const racecardsLoading = useSelector((state) => state.racecards.loading);
  const isLoading = gamesLoading || racecardsLoading || userStablesLoading;

  const c = classNames.bind(classes);
  const onStableSelection = (stable) => {
    updateSelectedStable && updateSelectedStable(stable);
  };

  return (
    <Col className={classNames(classes.myStablesContainer,
      c({ todayBackground: stableWidgetType.type === TODAY,
        maxHeight: height === 'MaxHeight' }))}>
      <Row className={classes.title}>
        <img className={classes.image} src={MyStablesImage} alt="horse"/>
        MY STABLES
        { stableWidgetType.title &&
          <React.Fragment> &nbsp;:&nbsp;
            <span className={classNames(c({ today: stableWidgetType.type === TODAY }))}>{stableWidgetType.title}</span>
          </React.Fragment>
        }
      </Row>
      { isLoading ?
        <Loader /> :
        <MyStablesList
          userStables={userStables}
          onStableSelection={onStableSelection}
          selectedStableId={selectedStableId}
          stableWidgetType={stableWidgetType}/>
      }
    </Col>
  );
};

export default MyStablesWidget;
