import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { getTracksListWithGames } from '../redux/selectors/games';

import * as _ from 'lodash';

const MAX_FUTURE_DATES = 5;

export function useDateLabels () {
  const today = moment().format('YYYY-MM-DD');
  const tomorrow = moment().add(1, 'days').format('YYYY-MM-DD');
  const oneDayAfter = moment(tomorrow).format('MMM').toUpperCase() + '. ' + moment(tomorrow).format('DD');
  const twoDays = moment().add(2, 'days').format('YYYY-MM-DD');
  const twoDaysAfter = moment(twoDays).format('MMM').toUpperCase() + '. ' + moment(twoDays).format('DD');
  const threeDays = moment().add(3, 'days').format('YYYY-MM-DD');
  const threeDaysAfter = moment(threeDays).format('MMM').toUpperCase() + '. ' + moment(threeDays).format('DD');

  const [dateLabels, setDateLabels] = useState([
    {
      id: 0,
      startDate: today,
      endDate: threeDays,
      name: 'ALL',
      races: true,
    },
    {
      id: 1,
      startDate: today,
      endDate: today,
      name: 'TODAY',
      races: false,
    },
    {
      id: 2,
      startDate: tomorrow,
      endDate: tomorrow,
      name: oneDayAfter,
      races: false,
    },
    {
      id: 3,
      startDate: twoDays,
      endDate: twoDays,
      name: twoDaysAfter,
      races: false,
    },
    {
      id: 4,
      startDate: threeDays,
      endDate: threeDays,
      name: threeDaysAfter,
      races: false,
    },
  ]);

  const allTracksDisplayList = useSelector(getTracksListWithGames);

  useEffect(() => {
    if (!allTracksDisplayList.length) {
      return;
    }

    _.forEach(_.range(MAX_FUTURE_DATES), (index) => {
      _.forEach(allTracksDisplayList, (track) => {
        if (dateLabels[index].startDate === _.get(track, 'games[0].racecard_obj.race_date')) {
          dateLabels[index].races = true;
        }
      });
    });

    setDateLabels(dateLabels);
  }, [allTracksDisplayList, dateLabels]);

  return dateLabels;
}
