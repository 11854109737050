import React from 'react';
import classes from './TrackHeader.module.scss';

const TrackHeader = ({ children }) => (
  <div className={`${classes.trackName}`}>
    {children || 'default text'}
  </div>
);

export default TrackHeader;
