import _ from 'lodash';
import moment from 'moment';

import { getRacecards } from './racecards';
import { createSelector } from 'reselect';
import { ServerState } from '../../shared/gameUtils';

export const getGames = ({ games }) => _.get(games, 'data');

export const getGamesWithRacecards = createSelector(
  getGames,
  getRacecards,
  (games, racecards) => _.map(games, (game) => {
    return {
      ...game,
      racecard_obj: racecards[game.racecard],
    };
  }),
);

export const getOpenGames = createSelector(
  getGames,
  (games) => _.filter(games, (game) => game.game_state === ServerState.OPEN),
);

export const gameByIdSelector = (id) => createSelector(
  getGames,
  (games) => _.find(games, (game) => game.id === id),
);

export const gameWithRacecardByIdSelector = (id) => createSelector(
  getGamesWithRacecards,
  (games) => _.find(games, (game) => _.get(game, 'id') === id),
);

export const getTracksListWithGames = createSelector(
  getGamesWithRacecards,
  (games) => {
    let tracksList = [];
    const today = moment().format('YYYY-MM-DD');
    _.map(games, (game) => {
      if (game.race_date >= today) {
        let index = tracksList.findIndex((track) => track.track_id === game.track.id);
        if (index !== -1) {
          tracksList[index].games.unshift(game);
        } else {
          const newTrack = {
            track_id: game.track.id,
            track: game.track,
            games: [game],
          };
          tracksList.push(newTrack);
        }
      }
    });

    return tracksList;
  },
);

export const getGamesByDate = createSelector(
  getGames,
  (games) => {
    const upcomingGames = _.filter(games, (game) => game.race_date >= moment().format('YYYY-MM-DD'));

    return _.chain(
      upcomingGames,
    ).map(
      (gameData) => {
        return gameData;
      },
    ).groupBy(
      'race_date',
    ).transform(
      (result, value, key) => {
        const newDateTracks = result;
        const trackGroup = _.groupBy(
          value,
          'track.name',
        );
        const arrayTrackGroup = _.transform(
          trackGroup,
          (obj, v, k) => {
            return obj.push({
              track: k,
              games: v,
            });
          },
          []);

        return _.set(newDateTracks, `${key}`, arrayTrackGroup);
      },
    ).value();
  },
);
