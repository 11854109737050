import { createSelector } from 'reselect';
import _ from 'lodash';
import { getGamesWithRacecards } from './games';
import { getTimeUntilFirstRace } from '../../shared/gameUtils';

export const getUserStablesLoading = ({ stables }) => {
  return _.get(stables, 'loading', false);
};

export const userStablesSelector = ({ stables }) => _.get(stables, 'data');

export const getUserStablesForGame = createSelector(
  userStablesSelector,
  (_, game) => game,
  (stables, game) => _.filter(stables, (stable) => stable.game === game.id),
);

export const getUserStablesWithGameAndRaceCard = createSelector(
  userStablesSelector,
  getGamesWithRacecards,
  (stables, games) =>
    _.map(stables, (stable) => {
      const game = _.find(games, (game) => game.id === stable.game);
      const cloneStable = {...stable};
      if (!_.isUndefined(game)) {
        cloneStable.game_obj = game;
        cloneStable.game_obj.timeUntilStart = getTimeUntilFirstRace(game);
      }

      return cloneStable;
    }),
);

export const userStablesStartedSelector = createSelector(
  getUserStablesWithGameAndRaceCard,
  (allUserStables) => _.filter(allUserStables, (stable) => !_.get(stable, 'game_obj.timeUntilStart')),
);

export const userStablesNotStartedSelector = createSelector(
  getUserStablesWithGameAndRaceCard,
  (allUserStables) => _.filter(allUserStables, (stable) => _.get(stable, 'game_obj.timeUntilStart')),
);

export const userStablesByIdSelector = createSelector(
  getUserStablesWithGameAndRaceCard,
  (_, id) => id,
  // eslint-disable-next-line
  (stables, id) => _.find(stables, (stable) => stable.id == id),
);
