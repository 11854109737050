import * as Actions from '../actions/tracks';

const initialState = {
  tracksData: null,
  tracksLoading: false,
  tracksError: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case Actions.FETCH_TRACKS_DATA:
      return {
        ...state,
        tracksLoading: true,
      };
    case Actions.FETCH_TRACKS_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        tracksData: action.data,
      };
    case Actions.FETCH_TRACKS_DATA_FAIL:
      return {
        ...state,
        tracksLoading: false,
        tracksError: action.error,
      };
    default: return state;
  }
};
