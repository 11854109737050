import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Redirect,
  Route,
  useRouteMatch,
} from 'react-router-dom';
import _ from 'lodash';
import moment from 'moment';
import { getTracksListWithGames } from '../../redux/selectors/games';

import TrackFilter from '../../components/TrackFilter';
import TrackHeader from '../../components/TrackHeader.js';
import GameDetails from '../../components/GameDetails';
import classes from './Tracks.module.scss';
import Loader from '../../components/Loader';

const Tracks = () => {
  const gamesLoading = useSelector((state) => state.games.loading);
  const racecardsLoading = useSelector((state) => state.racecards.loading);
  const isLoading = gamesLoading || racecardsLoading;

  const today = moment().format('YYYY-MM-DD');
  const threeDays = moment().add(3, 'days').format('YYYY-MM-DD');

  const [tracksDisplayList, setTrackDisplayList] = useState([]);

  const error = useSelector((state) => state.games.error);
  const allTracksDisplayList = useSelector(getTracksListWithGames);

  const match = useRouteMatch('/tracks/:start_date/:end_date');
  const startDate = _.get(match, 'params.start_date');
  const endDate = _.get(match, 'params.end_date');

  useEffect(() => {
    if (startDate === endDate) {
      const list = _.filter(allTracksDisplayList, (track) => (
        _.get(track, 'games[0].racecard_obj.race_date') === startDate
      ));
      setTrackDisplayList(list);
    } else {
      setTrackDisplayList(allTracksDisplayList);
    }
  }, [allTracksDisplayList, startDate, endDate]);

  let dateCol = 'hide';
  if (startDate !== endDate) {
    dateCol = 'show';
  }

  return (
    <div className="App-content">
      <Route exact path="/tracks">
        <Redirect from="/tracks" to={`/tracks/${today}/${threeDays}`} />
      </Route>
      <TrackFilter />
      {error && <p>Got ERROR: {error.message}</p>}
      { isLoading ?
        <Loader /> :
        _.isEmpty(tracksDisplayList) ?
          <p className={classes.noGame}>No Games Available</p> :
          tracksDisplayList && tracksDisplayList.map((track) => (
            <div key={track.track_id}>
              <TrackHeader>
                {track.track.name}
              </TrackHeader>
              {track.games.map((game) => (
                <div key={game.id}>
                  <GameDetails
                    game={game}
                    dateCol={dateCol}
                  />
                </div>
              ))}
            </div>
          ))
      }
    </div>
  );
};

export default Tracks;
