import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal, Row, Col } from 'react-bootstrap';
import moment from 'moment';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import classes from './HorseDetailsModal.module.scss';
import classNames from 'classnames/bind';
import { fetchRunner, clearRunner } from '../../redux/actions/runners';
import { surfaceTypes } from '../../shared/utility';
import InfoCircle from '../InfoCircle';

function HorseDetailsModal ({ runnerId, show, onHide, toggle }) {
  const runner = useSelector((state) => state.runners.runner);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchRunner(runnerId));

    return (() => {
      dispatch(clearRunner());
    });
  }, [dispatch, show, runnerId]);

  const risk = _.get(runner, 'custom_stats.risk') === 'N/A'
    ? 'default'
    : _.get(runner, 'custom_stats.risk', 'default').toLowerCase();

  const pedigreeLabel1 =
    _.get(runner, 'pedigree.sire', 'N/A') + ' - ' +
    _.get(runner, 'pedigree.dam.name', 'N/A') + ', by ' +
    _.get(runner, 'pedigree.dam.sire', 'N/A');

  const stable_count = _.get(runner, 'stable_count');
  const total_stables = _.get(runner, 'total_stables');

  const ownershipPercent = (stable_count != null && stable_count >= 0) && (total_stables > 0) &&
    `${(stable_count/total_stables).toFixed(2) * 100} %`;

  const ownershipFraction = (stable_count != null && stable_count >= 0) && (total_stables > 0) &&
    ` | ${stable_count} / ${total_stables}`;

  const foalingDate = _.get(runner, 'pedigree.foaling_date') &&
    moment(runner.pedigree.foaling_date, 'ddd MMM DD YYYY HH:mm:ss ZZ').format('MMMM DD, YYYY').toUpperCase();
  const pedigreeLabel2 =
    _.get(runner, 'pedigree.type', 'N/A') + ', ' +
    _.get(runner, 'pedigree.color', 'N/A') + ', ' +
    _.get(runner, 'pedigree.sex', 'N/A') + ', ' +
    'FOALED ' +
    foalingDate;

  const pastPerformances = _.assign(
    _.fill(
      Array(5),
      {
        date: '---',
        race: '---',
        track: '---',
        distance: '---',
        surface: '---',
        finishPosition: '',
        finishLength: '',
      },
    ),
    _.map(
      _.take(_.get(runner, 'past_performance'), 5),
      (performance) => {
        return {
          date: moment(performance.date, 'ddd MMM DD YYYY HH:mm:ss ZZ').format('MM/DD/YY'),
          race: performance.race.race_type,
          track: performance.race.track,
          distance: performance.distance,
          surface: surfaceTypes[performance.surface],
          finishPosition: performance.finish.position,
          finishLength: performance.finish.lengths,
        };
      },
    ),
  );

  const pastWorkouts = _.assign(
    _.fill(
      Array(6),
      {
        month: '',
        day: '',
        trackId: '',
        trackDistance: '',
        trackCondition: '',
        finishTiming: '',
        workoutType: '',
        finishRank: '',
        finishTotal: '',
      },
    ),
    _.map(
      _.take(_.get(runner, 'workouts'), 6),
      (workout) => {
        const minutes = workout.timing / 10000;
        const seconds = (workout.timing % 10000) / 100;

        const minutesString = (minutes).toFixed(0);

        let secondsString = (seconds).toFixed(0);
        secondsString = secondsString.length < 2 ? `0${secondsString}` : secondsString;

        const workoutTimingString = minutes >= 1 ? `${minutesString}:${secondsString}` : `:${secondsString}`;

        return {
          month: moment(workout.date, 'ddd MMM DD YYYY HH:mm:ss ZZ').format('MMM'),
          day: moment(workout.date, 'ddd MMM DD YYYY HH:mm:ss ZZ').format('DD'),
          trackId: workout.track.id,
          trackDistance: workout.track.distance.toLowerCase(),
          trackCondition: workout.track.condition.toLowerCase(),
          finishTiming: workoutTimingString,
          workoutType: workout.type,
          finishRank: workout.ranking.rank + '/',
          finishTotal: workout.ranking.total,
        };
      },
    ),
  );

  return (
    <Modal
      size="xl"
      show={show}
      onHide={onHide}
      centered
    >
      <Modal.Header
        className={classes.detailHeader}
        closeButton
      />
      {!runner ? <div>Loading ...</div> : (
        <Modal.Body
          className={classes.detailBody}
        >
          <Row className={classes.detailTopRow}>
            <Col
              xl="1"
              lg="2"
            >
              <div className={classNames(classes.postPosition,
                classes[`cloth_color_${runner.post_position}`])}>
                <div className={classes.postText}>
                  <div className={classes.postNumber}>
                    {runner.post_position}
                  </div>
                  {`R${runner.race_number}`}
                </div>
              </div>
            </Col>
            <Col
              xl="5"
              lg="10"
              className={classes.detailHorseName}
            >
              <h4 className={classes.detailBold}>{(runner.name).toUpperCase()}</h4>
            </Col>
            <Col
              xl="1"
              lg="2"
              xs="3"
            >
              <InfoCircle
                infoLabel="STARTS"
                infoValue={_.get(runner, 'career_stats.starts', '0')}
              />
            </Col>
            <Col
              xl="1"
              lg="2"
              xs="3"
            >
              <InfoCircle
                infoLabel="1ST"
                infoValue={_.get(runner, 'career_stats.wins', '0')}
              />
            </Col>
            <Col
              xl="1"
              lg="2"
              xs="3"
            >
              <InfoCircle
                infoLabel="2ND"
                infoValue={_.get(runner, 'career_stats.seconds', '0')}
              />
            </Col>
            <Col
              xl="1"
              lg="2"
              xs="3"
            >
              <InfoCircle
                infoLabel="3RD"
                infoValue={_.get(runner, 'career_stats.thirds', '0')}
              />
            </Col>
            <Col
              xl="2"
              lg="12"
            >
              <Row className={classes.detailShort}>
                <p className={classes.detailBold}>LIFETIME EARNINGS</p>
              </Row>
              <Row className={classes.detailCentered}>
                <p className={classes.detailGreen}>
                  ${_.get(runner, 'career_stats.earnings', '0').toLocaleString()}
                </p>
              </Row>
            </Col>
          </Row>

          <Row>
            <Col
              xl="1"
              lg="2"
            >
              <p className={classes.detailFollow}>FOLLOW</p>
            </Col>
            <Col
              xl="6"
              lg="10"
            >
              <Row>
                <Col lg="3">
                  <p className={classes.detailBold}>Pedigree:</p>
                </Col>
                <Col lg="9">
                  <p>
                    {pedigreeLabel1}
                    <br />
                    {pedigreeLabel2}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col lg="3">
                  <p className={classes.detailBold}>Jockey:</p>
                </Col>
                <Col lg="9">
                  <p>{_.get(runner, 'jockey', 'N/A')}</p>
                </Col>
              </Row>
              <Row>
                <Col lg="3">
                  <p className={classes.detailBold}>Trainer:</p>
                </Col>
                <Col lg="9">
                  <p>{_.get(runner, 'trainer', 'N/A')}</p>
                </Col>
              </Row>
              <Row>
                <Col lg="3">
                  <p className={classes.detailBold}>Owner(s):</p>
                </Col>
                <Col lg="9">
                  <p>{_.get(runner, 'owner', 'N/A')}</p>
                </Col>
              </Row>
              <Row>
                <Col lg="3">
                  <p className={classes.detailBold}>Breeder:</p>
                </Col>
                <Col lg="9">
                  <p>{runner.pedigree.breeder} ({runner.pedigree.foaling_area})</p>
                </Col>
              </Row>
            </Col>
            <Col
              xl="5"
              lg="12"
            >
              <Row>
                <p className={classes.detailRaces}>LAST 5 RACES</p>
              </Row>
              <Row>
                <Col xs="2">
                  <p className={classes.detailBold}>Date</p>
                </Col>
                <Col xs="2">
                  <p className={classes.detailBold}>Track</p>
                </Col>
                <Col xs="2">
                  <p className={classes.detailBold}>Race</p>
                </Col>
                <Col xs="3">
                  <p className={classes.detailBold}>Distance</p>
                </Col>
                <Col xs="2">
                  <p className={classes.detailBold}>Surface</p>
                </Col>
                <Col xs="1">
                  <p className={classes.detailBold}>Finish</p>
                </Col>
              </Row>
              {pastPerformances.map((perf, index) =>
                <Row key={index}>
                  <Col xs="2">
                    <p>{perf.date}</p>
                  </Col>
                  <Col xs="2">
                    <p>{perf.track}</p>
                  </Col>
                  <Col xs="2">
                    <p className={classes.detailBold}>{perf.race}</p>
                  </Col>
                  <Col xs="3">
                    <p>{perf.distance}</p>
                  </Col>
                  <Col xs="2">
                    <p>{perf.surface}</p>
                  </Col>
                  <Col xs="1">
                    <p className={classes.detailBold}>
                      {perf.finishPosition}<small><sup>{perf.finishLength}</sup></small>
                    </p>
                  </Col>
                </Row>,
              )}
            </Col>
          </Row>

          <Row className={classes.ownershipRow}>
            <Col
              xl="3"
              lg="6">
              {(ownershipPercent && ownershipFraction) &&
                <>
                  <span className={classes.detailBold}>OWNERSHIP: </span>
                  <span className={classes.detailBold}>{ownershipPercent} </span>
                  <span> {ownershipFraction} </span>
                </>
              }
            </Col>
            <Col
              xl="3"
              lg="6"
            >
              <span className={classes.detailBold}>SALARY: </span>
              <span className={classes.detailBold}>${_.get(runner, 'salary', '0').toLocaleString()}</span>
              <span> | </span>
              <span>{_.get(runner, 'odds', 'N/A')}</span>
            </Col>
            <Col
              xl="2"
              lg="3"
            >
              <Row className={classes.detailShort}>
                <p className={classes.detailBold}>SPEED RANK</p>
                <FontAwesomeIcon
                  className={classes.infoCircle}
                  icon={faInfoCircle}
                  onClick={toggle}
                />
              </Row>
              <Row className={classes.detailCentered}>
                <svg width="140" height="60">
                  <rect x="0" y="0" width="140" height="50" rx="25" fill="#dddddd" />
                  <text x="50%" y="50%" textAnchor="middle" fill="black" fontSize="24px" fontWeight="bold" dy=".3em">
                    {_.get(runner, 'custom_stats.speed.rank', 'N/A')}
                  </text>
                </svg>
              </Row>
              <Row className={classes.detailCentered}>
                <p className={classes.detailBold}>(OF 10)</p>
              </Row>
            </Col>
            <Col
              xl="2"
              lg="3"
            >
              <Row className={classes.detailShort}>
                <p className={classes.detailBold}>VALUE RANK</p>
                <FontAwesomeIcon
                  className={classes.infoCircle}
                  icon={faInfoCircle}
                  onClick={toggle}
                />
              </Row>
              <Row className={classes.detailCentered}>
                <svg width="140" height="60">
                  <rect x="0" y="0" width="140" height="50" rx="25" fill="#dddddd" />
                  <text x="50%" y="50%" textAnchor="middle" fill="black" fontSize="24px" fontWeight="bold" dy=".3em">
                    {_.get(runner, 'custom_stats.value.rank', 'N/A')}
                  </text>
                </svg>
              </Row>
              <Row className={classes.detailCentered}>
                <p className={classes.detailBold}>(OF 10)</p>
              </Row>
            </Col>
            <Col
              xl="2"
              lg="3"
            >
              <Row className={classes.detailShort}>
                <p className={classes.detailBold}>RISK</p>
                <FontAwesomeIcon
                  className={classes.infoCircle}
                  icon={faInfoCircle}
                  onClick={toggle}
                />
              </Row>
              <Row className={classes.detailCentered}>
                <svg width="140" height="60">
                  <rect x="0" y="0" width="140" height="50" rx="25"
                    className={classNames(classes[`risk_color_${risk}`])}
                  />
                  <text x="50%" y="50%" textAnchor="middle" fill="black" fontSize="24px" fontWeight="bold" dy=".3em">
                    {_.get(runner, 'custom_stats.risk', 'N/A').toUpperCase()}
                  </text>
                </svg>
              </Row>
            </Col>
          </Row>

          <Row>
            <span className={classes.detailBold}>WORKS&nbsp;</span>
            {pastWorkouts.map((workout, index) =>
              <Col xl="2" key={index} className={classes.workouts}>
                <span>&nbsp;{workout.month}</span>
                <span className={classes.detailBold}>
                  {workout.day}&nbsp;
                  {workout.trackId}&nbsp;
                  {workout.trackDistance}&nbsp;
                  {workout.trackCondition}&nbsp;
                  {workout.finishTiming}&nbsp;
                  {workout.workoutType}&nbsp;
                </span>
                <span className={classes.detailItalic}>{workout.finishRank}{workout.finishTotal}&nbsp;</span>
              </Col>,
            )}
          </Row>

        </Modal.Body>)}
    </Modal>
  );
}
export default HorseDetailsModal;
