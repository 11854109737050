import { useState, useCallback, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';

export default function usePrompt (when) {
  const history = useHistory();
  const unblock = useRef(null);
  const [showPrompt, setShowPrompt] = useState(false);
  const [lastLocation, setLastLocation] = useState(null);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);

  const cancelNavigation = useCallback(() => {
    setShowPrompt(false);
  }, []);

  const handleBlockedNavigation = useCallback(
    (nextLocation) => {
      if (!confirmedNavigation) {
        setShowPrompt(true);
        setLastLocation(nextLocation);

        return false;
      }

      return true;
    },
    [confirmedNavigation],
  );

  const confirmNavigation = useCallback(() => {
    setShowPrompt(false);
    setConfirmedNavigation(true);
  }, []);

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      history.push(lastLocation.pathname);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [confirmedNavigation, lastLocation]);

  useEffect(() => {
    if (when) {
      unblock.current = history.block(handleBlockedNavigation);
    } else {
      unblock.current = null;
    }

    return () => {
      if (unblock.current) {
        unblock.current();
      }
    };
  }, [when, history, handleBlockedNavigation]);

  return [showPrompt, confirmNavigation, cancelNavigation];
}
