import React from 'react';
import { PropTypes } from 'prop-types';
import { NavLink } from 'react-router-dom';
import classes from './TrackFilterItem.module.scss';

const TrackFilterItem = ({ link, exact, setActiveClass, races, children }) => {

  return (
    <NavLink
      data-testid={children}
      className={races ? classes.navItem : classes.navItemDisabled}
      to={link}
      exact={exact}
      activeClassName={setActiveClass ? classes.navItemActive : ''}>
      {children}
    </NavLink>
  );
};

TrackFilterItem.propTypes = {
  link: PropTypes.string.isRequired,
  children: PropTypes.string,
  setActiveClass: PropTypes.bool,
};

export default TrackFilterItem;
