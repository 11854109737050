import { sdApiRequest } from './StableDuelApi.js';

export const getRunners = () => {
  return sdApiRequest({
    method: 'GET',
    url: 'runners/',
  });
};

export const getRunnerById = (id) => {
  return sdApiRequest({
    method: 'GET',
    url: `runners/${id}/`,
  });
};

export const getRunnerByExternalId = (externalId) => {
  return sdApiRequest({
    method: 'GET',
    url: `horses/${externalId}/`,
  });
};
