import * as _ from 'lodash';
import { getUser } from './user';
import { gameWithRacecardByIdSelector } from './games';

const StableState = {
  UNKNOWN: 'UNKNOWN',
  NOT_STARTED_AND_VALID: 'NOT_STARTED_VALID',
  NOT_STARTED_AND_INVALID: 'NOT_STARTED_INVALID',
  STARTED_AND_VALID: 'STARTED_VALID',
  STARTED_AND_NEEDS_SCRATCH_REPLACEMENT: 'STARTED_NEEDS_SCRATCH_REPLACEMENT',
  STARTED_AND_IS_REPLACING_SCRATCH: 'STARTED_IS_REPLACING_SCRATCH',
  FINAL_RESULTS_PENDING: 'FINAL_RESULTS_PENDING',
  FINISHED: 'FINISHED',
  STARTED_AND_DISQUALIFIED: 'STARTED_DISQUALIFIED',
  FINISHED_AND_DISQUALIFIED: 'FINISHED_DISQUALIFIED',
};

const MAX_SCRATCHES = 3;

export const isLocallyEditing = ({ stableBuilder }) => {
  return stableBuilder.isLocallyEditing;
};

export const getStable = ({ stableBuilder }) => {
  return _.get(stableBuilder, 'stable');
};

export const getGame = (state) => {
  const stable = getStable(state);
  const id = _.get(stable, 'game');
  const gameSelector = gameWithRacecardByIdSelector(id);
  const game = gameSelector(state);

  return game;
};

export const getRunners = (state) => {
  return _.get(getStable(state), 'runners', []);
};

export const getRunnersWithInfo = (state) => {
  const stable = getStable(state);
  const runnerIds = _.get(stable, 'runners', []);
  const game = getGame(state);
  const runnersInfo = _.get(game, 'racecard_obj.runners');
  const userRunnersInfo = _.map(runnerIds,
    (id) => _.find(runnersInfo, (info) => info.id === id));

  return userRunnersInfo;
};

export const getStableSubmitting = ({ stableBuilder }) => {
  return _.get(stableBuilder, 'submitting', false);
};

export const scratchCount = (state) => {
  const scratchedRunners = _.filter(getRunners(state), { 'scratched': true });

  return scratchedRunners.length;
};

export const runnerSalaryTotal = (state) => {
  return _.sumBy(getRunnersWithInfo(state), 'salary');
};

export const remainingSalary = (state) => {
  const salaryCap = _.get(getGame(state), 'salary_cap', 0);

  return salaryCap > 0 ? salaryCap - runnerSalaryTotal(state) : 0;
};

export const numberOfRunnerLimit = (state) => {
  return _.get(getGame(state), 'runner_limit', 0);
};

export const numberOfRunnersInStable = (state) => {
  return getRunners(state).length;
};

export const numberOfEmptyRunners = (state) => {
  return numberOfRunnerLimit(state) - numberOfRunnersInStable(state);
};

export const isComplete = (state) => {
  return numberOfEmptyRunners(state) === 0 && remainingSalary(state) >= 0;
};

export const isValid = (state) => {
  return isComplete(state) && scratchCount(state) <= MAX_SCRATCHES;
};

export const averageSalaryPerHorse = (state) => {
  const count = numberOfRunnersInStable(state);

  return (count > 0) ? runnerSalaryTotal(state) / count : 0.0;
};

export const remainingSalaryPerHorse = (state) => {
  const emptyRunners = numberOfEmptyRunners(state);

  return remainingSalary(state) / (emptyRunners ? emptyRunners : 1.0);
};

export const hasScratchAndCanReplace = (state) => {
  const nextRace = _.get(getGame(state), 'nextRace', null);

  return isComplete(state) &&
    scratchCount(state) &&
    nextRace;
};

export const validAtStart = (state) => {
  const stable = getStable(state);

  return _.get(stable, 'is_valid_at_start', false);
};

export const getStableState = (state) => {
  const stable = getStable(state);

  return _.get(stable, 'state', false);
};

export const isOwnedByLoggedInUser = (state) => {
  return _.get(getStable(state), 'user') === _.get(getUser(state), 'id');
};

export const isEditable = (state) => {
  return isOwnedByLoggedInUser(state) &&
    _.includes(
      [ StableState.NOT_STARTED_AND_INVALID,
        StableState.NOT_STARTED_AND_VALID,
        StableState.STARTED_AND_IS_REPLACING_SCRATCH],
      getStableState(state));
};

export const isStartedAndCanReplaceScratch = (state) => {
  return isOwnedByLoggedInUser(state) &&
    _.includes(
      [ StableState.STARTED_AND_IS_REPLACING_SCRATCH,
        StableState.STARTED_AND_NEEDS_SCRATCH_REPLACEMENT],
      getStableState(state));
};

export const isDisqualified = (state) => {
  return _.includes(
    [ StableState.STARTED_AND_DISQUALIFIED,
      StableState.FINISHED_AND_DISQUALIFIED],
    getStableState(state));
};
