import React from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Nav } from 'react-bootstrap';
import classes from './Footer.module.scss';
import { logout } from '../redux/actions/Auth0';

const Footer = () => {
  const dispatch = useDispatch();
  const onLogout = () => {
    dispatch(logout());
  };

  return (
    <footer className={classes.SDfooter}>
      <Nav className={classes.nav}>
        <Link to="/settings">My Account</Link>
        <p>|</p>
        <Link to="/gamesupport">Game Support</Link>
        <p>|</p>
        <Link to="/howtoplay">How to Play</Link>
        <p>|</p>
        <Link to="/rules">Rules & Scoring</Link>
        <p>|</p>
        <Link to="/about">About StableDuel</Link>
        <p>|</p>
        <p className={classes.logout} onClick={onLogout}>&nbsp;&nbsp;&nbsp;Logout</p>
      </Nav>
      <Nav className={classes.nav}>
        <Link to="/termsofuse">Terms of Use</Link>
        <p>|</p>
        <Link to="/privacy">Privacy Policy</Link>
      </Nav>
    </footer>
  );
};
export default Footer;
