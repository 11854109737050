export const LOAD_USER_REQUEST = 'LOAD_USER_REQUEST';
export const LOAD_USER_SUCCESS = 'LOAD_USER_SUCCESS';
export const LOAD_USER_ERROR = 'LOAD_USER_ERROR';
export const SD_SIGNUP_SUCCESS = 'SD/SIGNUP_SUCCESS';
export const SD_SIGNUP_ERROR = 'SD/SIGNUP_ERROR';

export const loadUser = () => {
  return {
    type: LOAD_USER_REQUEST,
  };
};
