import React from 'react';
import _ from 'lodash';
import {
  Col,
  Row,
} from 'react-bootstrap';
import classNames from 'classnames/bind';
import classes from './MyStablesList.module.scss';
import MyStableRow from './MyStableRow';
import { LOBBY, FUTURE } from './MyStableTypes';

const MyStablesList = ({ userStables, onStableSelection, selectedStableId, stableWidgetType }) => {
  const c = classNames.bind(classes);

  return (
    _.map(userStables, (stables, key) =>
      <Row key={`stableRow${key}`}>
        <Col className={classes.stableList}>
          <Row className={classNames(classes.track,
            c({
              lobby: stableWidgetType.type === LOBBY,
              future: stableWidgetType.type === FUTURE,
            }))}>
            {key}
          </Row>
          <React.Fragment>
            {_.map(stables, (stable) =>
              < MyStableRow
                key={stable.stableId}
                stable={stable}
                onStableSelection={onStableSelection}
                selectedStableId={selectedStableId}
                stableWidgetType={stableWidgetType} />)}
          </React.Fragment>
        </Col>
      </Row>)
  );
};

export default MyStablesList;
