import React from 'react';
import { Row, Col } from 'react-bootstrap';
import classNames from 'classnames/bind';
import classes from './RunnerListRow.module.scss';

const RunnerListRow = ({ empty, runner, userRunner, updateRunners, disabled }) => {

  let isDisabled = '';
  let isScratched = '';
  if (!empty) {
    isScratched = runner.scratched || runner.disqualified;
    isDisabled = (disabled && userRunner.length === 0);
  }

  const bindClasses = classNames.bind(classes);
  const selectClasses = bindClasses({
    selected: userRunner.length > 0,
    disabled: isDisabled,
    scratched: isScratched,
  });

  return (
    <div className={classes.runnerList}>
      {empty ?
        (<Row className={classNames(classes.runnerListRow, selectClasses, 'flex-nowrap')}>
          <Col
            xs={2}
            className={classes.raceCardInfo}
          >
            <div className={classNames(classes.postPosition, classes['cloth_color_none'])}>
            </div>
          </Col>
          <Col
            xs={8}
            className={classes.displayName}
          >
            <div className={classes.selectName}>SELECT ANOTHER HORSE</div>
          </Col>
          <Col
            xs={2}
          >
            <div className={classes.selectionWrapper}>
              <div className={classNames(classes.selectionStatus, classes.notSelected)}>
              </div>
            </div>
          </Col>
        </Row>)
        :
        (<Row key={runner.id} className={classNames(classes.runnerListRow, selectClasses)}>
          {isDisabled && <div className={classes.disabledOverlay} />}
          {isScratched && <div className={classes.scratchedOverlay}>SCRATCH</div>}
          <Col
            xs={2}
            className={classes.raceCardInfo}
          >
            <div className={classNames(classes.postPosition, classes['cloth_color_' + runner.post_position])}>
              <div className={classes.postText}>
                <div className={classes.postNumber}>
                  {runner.post_position}
                </div>
                {`R${runner.race_number}`}
              </div>
            </div>
          </Col>
          <Col
            xs={8}
            className={classes.displayName}
          >
            <div className={classes.horseName}>
              {runner.name}
            </div>
          </Col>
          <Col
            xs={2}
          >
            <div className={classes.selectionWrapper} onClick={() => updateRunners(runner)}>
              <div className={classNames(classes.selectionStatus, selectClasses)}>
              </div>
            </div>
          </Col>
        </Row>)
      }
    </div>
  );
};

export default RunnerListRow;
