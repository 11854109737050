import { put, takeEvery, call, select, all } from 'redux-saga/effects';
import moment from 'moment';
import { getGamesByDateRange, getGameById } from '../../api/GamesApi';
import {
  FETCH_GAMES_UPCOMING,
  FETCH_GAMES_BY_DATES,
  FETCH_GAME_BY_ID,
  fetchMultipleGamesSuccess,
  fetchSingleGameSuccess,
  fetchGamesFail,
} from '../actions/games';

import {
  fetchRacecardById,
} from '../actions/racecards';

import { gameByIdSelector } from '../selectors/games';

import * as _ from 'lodash';

function* fetchGamesUpcoming () {
  const upcomingDates = {
    start_date: moment().format('YYYY-MM-DD'),
    end_date: moment().add(5, 'days').format('YYYY-MM-DD'),
  };

  yield call(fetchGamesByDateRange, upcomingDates);
}

function* fetchGamesByDateRange ({ start_date, end_date }) {
  try {
    const response = yield call(getGamesByDateRange, {start_date, end_date});
    const games = response.results;

    // TODO: fix this once endpoint to fetch racecards by array of ids is available
    const racecardTasks = _.map(games, (game) => put(fetchRacecardById(game.racecard)));
    yield all(racecardTasks);

    yield put(fetchMultipleGamesSuccess(games));
  } catch (error) {
    yield put(fetchGamesFail(error));
  }
}

function* fetchGameById ({ id }) {
  try {
    let game = yield select(gameByIdSelector(id), id);
    if (_.isEmpty(game)) {
      game = yield call(getGameById, id);
      yield put(fetchRacecardById(game.racecard));
    }
    yield put(fetchSingleGameSuccess(game));
  } catch (error) {
    yield put(fetchGamesFail(error));
  }
}

export default function* gamesSaga () {
  yield takeEvery(FETCH_GAMES_UPCOMING, fetchGamesUpcoming);
  yield takeEvery(FETCH_GAMES_BY_DATES, fetchGamesByDateRange);
  yield takeEvery(FETCH_GAME_BY_ID, fetchGameById);
}
