import { sdApiRequest } from './StableDuelApi.js';

export const getRacecards = () => {
  return sdApiRequest({
    method: 'GET',
    url: 'racecards/',
  });
};

export const getRacecardById = (id) => {
  return sdApiRequest({
    method: 'GET',
    url: `racecards/${id}/`,
  });
};

export const getRunnerById = (id) => {
  return sdApiRequest({
    method: 'GET',
    url: `runners/${id}/`,
  });
};

export const getRunnerByExternalId = (externalId) => {
  return sdApiRequest({
    method: 'GET',
    url: `horses/${externalId}/`,
  });
};
