import axios from 'axios';

const cmsBaseUrl = process.env.REACT_APP_CMS_API_ROOT;

const cmsAxiosInstance = axios.create({
  baseURL: cmsBaseUrl,
});

const cmsHtmlContentUrl = 'jsonapi/node/html_content';

const cmsGetHtmlContent = (title) => {
  const cmsUrl = `${cmsBaseUrl}${cmsHtmlContentUrl}?filter[title]=${title}&fields[node--html_content]=body,title`;

  const onSuccess = (response) => {
    return response.data;
  };

  const onError = (error) => {
    return Promise.reject(error.response || error.message);
  };

  return cmsAxiosInstance.get(cmsUrl)
    .then(onSuccess)
    .catch(onError);
};

export default cmsGetHtmlContent;
