import React from 'react';
import {Row, Col } from 'react-bootstrap';
import classes from './RaceCard.module.scss';
import RaceCardHeader from './RaceCardHeader';
import RaceCardRow from './RaceCardRow';
import { surfaceTypes } from '../../shared/utility';

const RaceCard = ({
  index,
  runners,
  userRunners,
  race,
  onUpdateRunners,
  remainingSalary,
  runnerLimit,
}) => {
  const raceDetails = [race.race_type_description, race.distance, surfaceTypes[race.surface]];

  return (
    <span>
      <Row className={classes.race}>
        <Col className="px-0">
          <div className={classes.raceInfo}>
            <span className={classes.raceIndex}>
              {`RACE ${index}`}
              {RaceCard.race_name && `| ${race.race_name}`}
            </span>
            &nbsp;|&nbsp;{raceDetails.join(' | ')}
          </div>
          <RaceCardHeader />
        </Col>
      </Row>
      <Row>
        <Col className={classes.horseRows}>
          {runners.map((runner) => <RaceCardRow runner={runner}
            key={runner.id}
            userRunner={userRunners.filter((userRunner) => userRunner.id === runner.id)}
            updateRunners={onUpdateRunners}
            disabled={runner.salary > remainingSalary || userRunners.length >= runnerLimit}
          />)}
        </Col>
      </Row>
    </span>
  );
};

export default RaceCard;
