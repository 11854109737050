import React from 'react';
import _ from 'lodash';

import {
  Row,
  Col,
} from 'react-bootstrap';

import EnterGameBtn from '../common/EnterGameBtn';
import classes from './TrackWidgetGameDetails.module.scss';

const TrackWidgetGameDetails = ({
  game,
}) => {
  let currentPool = _.get(game, 'pool');

  if (currentPool > 0) {
    currentPool = currentPool.toLocaleString(navigator.language, { minimumFractionDigits: 0 });
  }

  return (
    <Col className={classes.wrapper}>
      <Row className={classes.rowOne}>
        <p className={classes.gameName}>{game.name || 'Game Name'}</p>
      </Row>
      <Row className={classes.rowTwo}>
        <Col
          lg="6"
          className={classes.colOne}>
          <Row className={`${classes.stables} justify-content-sm-center justify-content-lg-start`}>
            ACTIVE STABLES
            <span className={classes.largeNumber}>{game.stable_count}</span>
          </Row>
          <Row className={`${classes.currentPool} justify-content-sm-center justify-content-lg-start`}>
            <p>CURRENT POOL
              <span className={classes.currentAmt}>${currentPool}</span>
            </p>
          </Row>
        </Col>
        <Col
          lg="6"
          className={`${classes.colTwo} justify-content-sm-center`}>
          <EnterGameBtn styling="outline-trackWidget" game={game}/>
        </Col>
      </Row>
    </Col>
  );
};

export default TrackWidgetGameDetails;
