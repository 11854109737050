import React from 'react';
import {
  Container,
  Row,
  Col,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';

import classes from './GameSupport.module.scss';
import { Search } from '../../components/forms/Input';

const GameSupport = () => (
  <Container fluid className={`${classes.gameSupport} px-5`}>
    <Row className={`${classes.mainHeader} justify-content-center`}>
      Game Support
    </Row>
    <Row className={`${classes.contentRow} mt-3 justify-content-center`}>
      <Col lg className={`${classes.col} mt-3 mr-2`}>
        <Row className={`${classes.FAQ} justify-content-center mt-4 mx-4`}>
          FAQ
        </Row>
        <Row className="mt-4 text-end">
          <Col className={`${classes.headerTxt} pt-2`}>
            We&lsquo;re here to help.
          </Col>
          <Col className={classes.searchBar}>
            <div>
              <Search placeholder="Search FAQ" />
              <FontAwesomeIcon className={classes.icon} icon={faSearch} size="1x" color="white" aria-hidden="true" />
            </div>
          </Col>
        </Row>
        <Row className="ml-3">
          <Col className={`${classes.txt}`}>
            Take a look through our frequently asked questions to see if we already have the answer for <br />
            you. Browse by topic below, or try our search. If you don&lsquo;t see the answer
            you&lsquo;re looking for, <br /> please use our contact.
          </Col>
        </Row>
        <Row className="mx-auto mt-4 justify-content-center">
          <Col className="px-4">
            <Accordion allowZeroExpanded>
              <AccordionItem className="mt-2 text-center">
                <AccordionItemHeading>
                  <AccordionItemButton className={classes.accordianBtn}>
                    Gameplay
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <Row className={`${classes.itemQs} text-left mt-3`}>
                    <Col md="6">
                      <ul>
                        <li>How many times can I enter a contest?</li>
                        <li className="mt-2">Is there a limit on the number of contests I can enter?</li>
                        <li className="mt-2">How do the points work?</li>
                        <li className="mt-2">The game is over, but my points have not updated! Is it broken?</li>
                      </ul>
                    </Col>
                    <Col md="6">
                      <ul>
                        <li>How do I replace a scratched horse?</li>
                        <li className="mt-2">What happens if my horse gets disqualified?</li>
                        <li className="mt-2">How do the points work?</li>
                        <li className="mt-2">What if I pick an AE (also-eligible) horse in my stable?</li>
                      </ul>
                    </Col>
                  </Row>
                </AccordionItemPanel>
              </AccordionItem>
              <AccordionItem className="mt-4 text-center">
                <AccordionItemHeading>
                  <AccordionItemButton className={classes.accordianBtn}>
                      Rewards
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel className={classes.itemQs}>
                  <Row className={`${classes.itemQs} text-left mt-3`}>
                    <Col md="6">
                      <ul>
                        <li>How many times can I enter a contest?</li>
                        <li className="mt-2">Is there a limit on the number of contests I can enter?</li>
                        <li className="mt-2">How do the points work?</li>
                        <li className="mt-2">The game is over, but my points have not updated! Is it broken?</li>
                      </ul>
                    </Col>
                    <Col md="6">
                      <ul>
                        <li>How do I replace a scratched horse?</li>
                        <li className="mt-2">What happens if my horse gets disqualified?</li>
                        <li className="mt-2">How do the points work?</li>
                        <li className="mt-2">What if I pick an AE (also-eligible) horse in my stable?</li>
                      </ul>
                    </Col>
                  </Row>
                </AccordionItemPanel>
              </AccordionItem>
              <AccordionItem className="mt-4 text-center">
                <AccordionItemHeading>
                  <AccordionItemButton className={classes.accordianBtn}>
                      Deposits & Withdrawals
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <Row className={`${classes.itemQs} text-left mt-3`}>
                    <Col md="6">
                      <ul>
                        <li>How many times can I enter a contest?</li>
                        <li className="mt-2">Is there a limit on the number of contests I can enter?</li>
                        <li className="mt-2">How do the points work?</li>
                        <li className="mt-2">The game is over, but my points have not updated! Is it broken?</li>
                      </ul>
                    </Col>
                    <Col md="6">
                      <ul>
                        <li>How do I replace a scratched horse?</li>
                        <li className="mt-2">What happens if my horse gets disqualified?</li>
                        <li className="mt-2">How do the points work?</li>
                        <li className="mt-2">What if I pick an AE (also-eligible) horse in my stable?</li>
                      </ul>
                    </Col>
                  </Row>
                </AccordionItemPanel>
              </AccordionItem>
              <AccordionItem className="mt-4 text-center">
                <AccordionItemHeading>
                  <AccordionItemButton className={classes.accordianBtn}>
                      Account
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <Row className={`${classes.itemQs} text-left mt-3`}>
                    <Col md="6">
                      <ul>
                        <li>How many times can I enter a contest?</li>
                        <li className="mt-2">Is there a limit on the number of contests I can enter?</li>
                        <li className="mt-2">How do the points work?</li>
                        <li className="mt-2">The game is over, but my points have not updated! Is it broken?</li>
                      </ul>
                    </Col>
                    <Col md="6">
                      <ul>
                        <li>How do I replace a scratched horse?</li>
                        <li className="mt-2">What happens if my horse gets disqualified?</li>
                        <li className="mt-2">How do the points work?</li>
                        <li className="mt-2">What if I pick an AE (also-eligible) horse in my stable?</li>
                      </ul>
                    </Col>
                  </Row>
                </AccordionItemPanel>
              </AccordionItem>
            </Accordion>
          </Col>
        </Row>
      </Col>
      <Col lg className={`${classes.col} mt-3 ml-2`}>
        <Row className={`${classes.contactUs} justify-content-center mt-4 mx-4`}>
          CONTACT US
        </Row>
      </Col>
    </Row>
  </Container>
);

export default GameSupport;
