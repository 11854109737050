import * as Actions from '../actions/user';

export const initialState = {
  data: null,
  loading: false,
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case Actions.LOAD_USER_REQUEST:
      return {
        ...state,
        loading: true,
        error: initialState.loadUserError,
      };
    case Actions.LOAD_USER_SUCCESS:
      return {
        ...state,
        data: action.user,
        loading: false,
        error: initialState.error,
      };
    case Actions.LOAD_USER_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};

