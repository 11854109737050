import * as _ from 'lodash';
import moment from 'moment';

export const GameState = {
  UNKNOWN: 0,
  NOT_STARTED: 1,
  LIVE: 2,
  RESULTS_PENDING: 3,
  FINISHED: 4,
  CANCELLED: 5,
};

export const ServerState = {
  UNKNOWN: 'UNKNOWN',
  OPEN: 'OPEN',
  LIVE: 'LIVE',
  RESULTS_PENDING: 'RESULTS_PENDING',
  FINISHED: 'FINISHED',
  CANCELLED: 'CANCELLED',
};

export const canJoinGame = (game, numberOfUserStablesForGame) => {
  if (_.get(game, 'started')) {
    return false;
  }

  if (_.get(game,'stable_limit') === -1) {
    return true;
  }

  if (numberOfUserStablesForGame < _.get(game, 'stable_limit')) {
    return true;
  }

  return false;
};

export const getTimeUntilPost = (race) => {
  const raceDate = _.get(race, 'adjusted_date');
  const postTime = _.get(race, 'post_time');

  const offset = moment().utcOffset();
  const localRaceMoment = moment.utc(raceDate + ' ' + postTime, 'YYYY-MM-DD HH:mmA').utcOffset(offset);
  const localCurrentMoment = moment.utc().utcOffset(offset);

  return localCurrentMoment < localRaceMoment ? localRaceMoment.diff(localCurrentMoment) : 0;
};

export const getTimeUntilFirstRace = (game) => {
  return getTimeUntilPost(_.get(game, 'racecard_obj.races[0]'));
};

export const getServerState = (game) => {
  return ServerState[_.get(game, 'game_state')];
};

export const getLastRace = (game) => {
  return _.last(_.get(game, 'racecard_obj.races', []));
};

export const getGameState = (game) => {
  if (game) {
    const lastRace = getLastRace(game);
    if (getServerState(game) === ServerState.CANCELLED) {
      return GameState.CANCELLED;
    } else if (getTimeUntilFirstRace(game)) {
      return GameState.NOT_STARTED;
    } else if ( lastRace ) {
      if (getTimeUntilPost(lastRace)) {
        return GameState.LIVE;
      } else if (lastRace.results_are_in) {
        return GameState.FINISHED;
      } else {
        return GameState.RESULTS_PENDING;
      }
    }
  }

  return GameState.UNKNOWN;
};

export const isStarted = (game) => {
  return _.get(game,'started');
};

export const getEntryFee = (game) => {
  const entryFee = _.get(game, 'entry_fee', '0.00');

  return entryFee === '0.00' || entryFee === null ? 'FREE' : `$${entryFee}`;
};
