import React, { useState } from 'react';
import {
  Col,
  Container,
  Row,
} from 'react-bootstrap';
import LinkBtn from './LinkBtn';
import Button from './Button.js';
import * as _ from 'lodash';
import DeleteStableModal from './modals/DeleteStableModal';
import MyStablesHorseListRow from './MyStablesHorseListRow';

const MAX_RUNNERS = 10;

const MyStablesHorseList = ({ stable }) => {
  const [modalShow, setModalShow] = useState(false);
  const userRunners = _.orderBy(_.get(stable, 'runners', []), ['race_number','post_position'], ['asc', 'asc']);
  const isFuture = _.get(stable, 'game.timeUntilStart', 0) > 0;
  const blankEntries = _.get(stable, 'game.runner_limit', MAX_RUNNERS) - userRunners.length;
  const stableId = _.get(stable, 'stableId');

  return (
    <Container>
      { userRunners.length > 0 ?
        (_.map(userRunners, (runner) =>
          (!_.isUndefined(runner) && <MyStablesHorseListRow
            key={_.get(runner, 'id')}
            game={_.get(stable, 'game')}
            runner={runner}
            isFuture={isFuture}
          />),
        )) :
        'Invalid Stable: No horses in the stable'
      }
      {isFuture && _.range(0, blankEntries).map((current) =>
        <MyStablesHorseListRow
          key={`mystableshorseblank-${current}`}
          game={_.get(stable, 'game')}
          runner={null}
          isFuture={false}
        />)
      }
      {isFuture &&
        <Row>
          <Col xl='6' >
            <Button styling={'redOutlineBlue'} clickAction={() => setModalShow(true)}>DELETE STABLE</Button>
            <DeleteStableModal
              show={modalShow}
              onHide={() => setModalShow(false)}
            />
          </Col>
          <Col xl='6'>
            <LinkBtn
              label='EDIT STABLE'
              styling='whiteOutlineBlue'
              path={`/stables/edit/${stableId}`}
            />
          </Col>
        </Row>}
    </Container>
  );
};

export default MyStablesHorseList;
