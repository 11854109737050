import React, { useRef } from 'react';
import classNames from 'classnames/bind';
import {
  FormGroup,
  FormControl,
} from 'react-bootstrap';

import classes from './Input.module.scss';

export default function TextInput ({ value, name, placeholder, onBlur, onChange, onFocus, error }) {

  const c = classNames.bind(classes);

  return (
    <FormGroup className={classes.inputWrapper}>
      <FormControl
        defaultValue={value}
        name={name}
        placeholder={placeholder}
        type="text"
        onBlur={onBlur}
        onChange={onChange}
        onFocus={onFocus}
        className={classNames(classes.textInput, c({inValid: error}))}
      />
      {error && (
        <div className={classes.errorText}>{error}</div>
      )}
    </FormGroup>
  );
}

export function Email ({ value, name, placeholder, onBlur, onChange, onFocus, error }) {

  const c = classNames.bind(classes);

  return (
    <FormGroup className={classes.inputWrapper}>
      <FormControl
        defaultValue={value}
        name={name}
        placeholder={placeholder}
        type="email"
        onBlur={onBlur}
        onChange={onChange}
        onFocus={onFocus}
        className={classNames(classes.textInput, c({inValid: error}))}
      />
      {error && (
        <div className={classes.errorText}>{error}</div>
      )}
    </FormGroup>
  );
}

export function Password ({ value, name, placeholder, onBlur, onChange, onFocus, error, type }) {

  const c = classNames.bind(classes);

  return (
    <FormGroup className={classes.inputWrapper}>
      <FormControl
        name={name}
        value={value}
        placeholder={placeholder}
        type={type}
        onBlur={onBlur}
        onChange={onChange}
        onFocus={onFocus}
        className={classNames(classes.textInput, c({inValid: error}))}
      />
      {error && (
        <div className={classes.errorText}>{error}</div>
      )}
    </FormGroup>
  );
}

export function Birthdate ( { value, name, placeholder, onBlur, onChange, error }) {

  const c = classNames.bind(classes);
  const inputEl = useRef(null);
  const handleFocus = () => {
    inputEl.current.type = 'date';
  };

  return (
    <FormGroup className={classes.inputWrapper}>
      <FormControl
        ref={inputEl}
        name={name}
        placeholder={placeholder}
        value={value}
        type="text"
        onBlur={onBlur}
        onChange={onChange}
        onFocus={handleFocus}
        className={classNames(classes.textInput, c({inValid: error}))}
      />
      {error && (
        <div className={classes.errorText}>{error}</div>
      )}
    </FormGroup>
  );
}

export function Countries ({ value, name, placeholder, onBlur, onChange, onFocus, error }) {

  const c = classNames.bind(classes);

  return (
    <FormGroup className={classes.inputWrapper}>
      <FormControl
        as="select"
        value={value}
        name={name}
        placeholder={placeholder}
        onBlur={onBlur}
        onChange={onChange}
        onFocus={onFocus}
        className={classNames(classes.textInput, c({inValid: error}))}
      >
        <option value="">Country..</option>
        <option value="USA">United States</option>
      </FormControl>
      {error && (
        <div className={classes.errorText}>{error}</div>
      )}
    </FormGroup>
  );
}

export function Search ({ value, name, placeholder, onBlur, onChange, onFocus, error }) {

  const c = classNames.bind(classes);

  return (
    <FormGroup className={classes.inputWrapper}>
      <FormControl
        defaultValue={value}
        name={name}
        placeholder={placeholder}
        type="text"
        onBlur={onBlur}
        onChange={onChange}
        onFocus={onFocus}
        className={classNames(classes.search, c({inValid: error}))}
      />
      {error && (
        <div className={classes.errorText}>{error}</div>
      )}
    </FormGroup>
  );
}
