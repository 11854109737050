import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';

const PublicRoute = ({ component: Component, restricted, ...rest }) => {
  const isAuthenticated = useSelector((state) => state.account.authenticated);
  
  return (
    <Route {...rest}
      render={(props) => (
        isAuthenticated && restricted ? <Redirect to="/lobby" /> : <Component {...props} />
      )} />
  );
};
export default PublicRoute;
