import React from 'react';
import { useSelector } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import { Modal } from 'react-bootstrap';
import Button from '../Button.js';
import classes from './DeleteStableModal.module.scss';
import Loader from '../Loader';

function DeleteStableModal ({ show, onHide, onDeleteStable }) {
  const stableDeleting = useSelector((state) => state.stableBuilder.deleting);

  return (
    <Modal
      show={show}
      onHide={onHide}
      centered
    >
      <Modal.Header
        className={classes.deleteHeader}
        closeButton
      >
      </Modal.Header>
      <Modal.Body
        className={classes.deleteBody}
      >
        <p> Are you sure you want to remove this stable from the contest?</p>
        <Row>
          <Col>
            <Button
              styling={'redOutline'}
              clickAction={onDeleteStable}
              disabled={stableDeleting}
            >
              {stableDeleting ? <Loader /> : 'YES - DELETE'}
            </Button>
          </Col>
          <Col>
            <Button styling={'black'} clickAction={() => onHide(true)}>NO - CANCEL</Button>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
}
export default DeleteStableModal;
