import * as Actions from '../actions/runners';

const initialState = {
  runner: null,
  runnerLoading: false,
  runnerError: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case Actions.FETCH_RUNNER:
      return {
        ...state,
        runnerLoading: true,
      };
    case Actions.FETCH_RUNNER_SUCCESS:
      return {
        ...state,
        runnerLoading: false,
        runner: action.data,
      };
    case Actions.FETCH_RUNNER_FAIL:
      return {
        ...state,
        runnerLoading: false,
        runnerError: action.error,
      };
    case Actions.CLEAR_RUNNER:
      return {
        ...state,
        runner: null,
      };
    default: return state;
  }
};
