import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames/bind';
import classes from './LinkBtn.module.scss';

const LinkBtn = ({ label, path, styling = '', disabled = false}) => {
  const bindClasses = classNames.bind(classes);

  return (
    <Link
      data-testid='linkbtn'
      className={classNames(
        classes.btnLink,
        classes[styling],
        bindClasses({ disabledBtn : disabled }),
      )}
      to={path}>
      {label ||  'Link'}
    </Link>
  );
};

export default LinkBtn;
