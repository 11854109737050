import React, {useState} from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  Row,
  Col,
} from 'react-bootstrap';
import { 
  Form,
  Field,
} from 'react-final-form';

import Button from '../../components/Button';
import Loader from '../../components/Loader';
import { Email, Password } from './Input';
import classes from './LoginForm.module.scss';
import {
  composeValidators,
  required,
} from './Validators';
import { login } from '../../redux/actions/Auth0';

export default function LoginForm () {
  const dispatch = useDispatch();
  const loginError = useSelector((state) => state.account.loginError);
  const loginPending = useSelector((state) => state.account.loginPending);

  const [passwordShown, setPasswordShown] = useState(false);
  const togglePasswordVisiblity = () => {
    setPasswordShown(!passwordShown);
  };

  const onSubmit = (params) => {
    dispatch(login(params));
  };

  return(
    <Form onSubmit={onSubmit}>
      {({handleSubmit}) => <form onSubmit={handleSubmit} className={classes.wrapper}>
        <Row className={`${classes.rowWrapper} justify-content-md-center`}>
          <Col md={6} className={classes.col}>
            <Field name='email' validate={composeValidators(required)}>
              {({input, meta})=>
                <Email
                  name={input.name}
                  placeholder='Email'
                  onBlur={input.onBlur}
                  onChange={input.onChange}
                  onFocus={input.onFocus}
                  value={input.value}
                  error={meta.touched && meta.error}
                />
              }
            </Field>
          </Col>
        </Row>
        <Row className={`${classes.rowWrapper} justify-content-md-center`}>
          <Col md={6} className={classes.col}>
            <Field name='password' validate={
              composeValidators(required)}>
              {({input, meta}) => (
                <div>
                  <Password
                    name={input.name}
                    placeholder='Password'
                    onBlur={input.onBlur}
                    onChange={input.onChange}
                    onFocus={input.onFocus}
                    value={input.value}
                    error={meta.touched && meta.error}
                    type={passwordShown ? 'text' : 'password'}
                  />
                  <i onClick={togglePasswordVisiblity}>{passwordShown ? 'Hide' : 'Show'}</i>
                </div>
              )}
            </Field>
          </Col>
        </Row>
        <Row className={`${classes.forgetPass} justify-content-md-center`}>
          <Col md={6} className={classes.col}>
            <div className={classes.errorTxt} >{loginError}</div>
            <Link className={classes.linkTxt} to="/forgotpassword"> Forget Password? </Link>
          </Col>
        </Row>
        <Row>
          <Col lg="6" className="mx-auto">
            <Button className={classes.btn} type='submit' styling='outline-large'>
              {loginPending ? <Loader /> : 'Login'}
            </Button>
          </Col>
        </Row>
      </form>}
    </Form>
  );
}
