export const FETCH_GLOBAL_LEADER_BOARD = 'GLB/FETCH_DATA';
export const FETCH_GLOBAL_LEADER_BOARD_SUCCESS = 'GLB/FETCH_DATA_SUCCESS';
export const FETCH_GLOBAL_LEADER_BOARD_FAIL = 'GLB/FETCH_DATA_FAIL';
export const CLEAR_STABLES = 'GLB/CLEAR_STABLES';

export const fetchGlobalLeaderBoard = (page) => ({
  type: FETCH_GLOBAL_LEADER_BOARD,
  page,
});

export const fetchGlobalLeaderBoardSuccess = (data) => ({
  type: FETCH_GLOBAL_LEADER_BOARD_SUCCESS,
  data,
});

export const fetchGlobalLeaderBoardFail = (error) => ({
  type: FETCH_GLOBAL_LEADER_BOARD_FAIL,
  error,
});

export const clearStables = () => ({
  type: CLEAR_STABLES,
});
