import {
  all,
  put,
  call,
  takeLatest,
  select,
} from 'redux-saga/effects';

import {
  LOAD_USER_REQUEST,
  LOAD_USER_SUCCESS,
  LOAD_USER_ERROR,
  SD_SIGNUP_SUCCESS,
  SD_SIGNUP_ERROR,
} from '../actions/user';

import {
  fetchUserStables,
} from '../actions/userStables';

import {
  AUTH0_VERIFY_TOKEN_SUCCESS,
  AUTH0_SIGNUP_SUCCESS,
} from '../actions/Auth0';

import { login } from './Auth0';
import { createNewUser, fetchUser } from '../../api/UserApi';
import { getToken } from '../selectors/account';
import { fetchUpcomingGames } from '../actions/games';

export function* loadUser () {
  try {
    const token = yield select(getToken);
    const userData = yield fetchUser(token);

    // Pre-fetch data that we will need
    yield put(fetchUpcomingGames());
    yield put(fetchUserStables(userData.id));

    yield put({ type: LOAD_USER_SUCCESS, user: userData });
  } catch (error) {
    yield put({ type: LOAD_USER_ERROR, error: error.statusText });
  }
}

export function* SDsignup (action) {
  const { response, formData } = action;
  const user = {
    user: {
      email: response.email,
      first_name: response.givenName,
      last_name: response.familyName,
      auth0_id: 'auth0|' + response.Id,
    },
    profile: {
      birthdate: formData.birthdate,
      zip_code: formData.zip_code,
      country: formData.country,
    },
  };
  try {
    yield call(createNewUser, user);
    yield put({ type: SD_SIGNUP_SUCCESS, formData });
  } catch (error) {
    yield put({ type: SD_SIGNUP_ERROR, error: error.statusText });
  }
}

export function* user () {
  yield takeLatest([LOAD_USER_REQUEST, AUTH0_VERIFY_TOKEN_SUCCESS], loadUser);
  yield takeLatest(AUTH0_SIGNUP_SUCCESS, SDsignup);
  yield takeLatest(SD_SIGNUP_SUCCESS, login);
}

export default function* () {
  yield all([
    user(),
  ]);
}
