export const AUTH0_LOGIN_REQUEST = 'AUTH0/LOGIN_REQUEST';
export const AUTH0_LOGIN_SUCCESS = 'AUTH0/LOGIN_SUCCESS';
export const AUTH0_LOGIN_ERROR = 'AUTH0/LOGIN_ERROR';

export const AUTH0_SIGNUP_REQUEST = 'AUTH0/SIGNUP_REQUEST';
export const AUTH0_SIGNUP_SUCCESS = 'AUTH0/SIGNUP_SUCCESS';
export const AUTH0_SIGNUP_ERROR = 'AUTH0/SIGNUP_ERROR';

export const AUTH0_LOGOUT_REQUEST = 'AUTH0/LOGOUT_REQUEST';
export const AUTH0_LOGOUT_ERROR = 'AUTH0/LOGOUT_ERROR';

export const AUTH0_CALLBACK_RESPONSE = 'AUTH0/CALLBACK_RESPONSE';
export const AUTH0_CALLBACK_RESPONSE_ERROR = 'AUTH0/CALLBACK_RESPONSE_ERROR';

export const AUTH0_VERIFY_TOKEN_REQUEST = 'AUTH0/VERIFY_TOKEN_REQUEST';
export const AUTH0_VERIFY_TOKEN_SUCCESS = 'AUTH0/VERIFY_TOKEN_SUCCESS';
export const AUTH0_VERIFY_TOKEN_ERROR = 'AUTH0/VERIFY_TOKEN_ERROR';

export const AUTH0_FORGOT_PASSWORD_REQUEST = 'AUTH0/FORGOT_PASSWORD_REQUEST';
export const AUTH0_FORGOT_PASSWORD_SUCCESS = 'AUTH0/FORGOT_PASSWORD_SUCCESS';
export const AUTH0_FORGOT_PASSWORD_ERROR = 'AUTH0/FORGOT_PASSWORD_ERROR';

export const AUTH0_CLEAR_ERRORS = 'AUTH0/CLEAR_ERRORS';

export const login = (formData) => {
  return {
    type: AUTH0_LOGIN_REQUEST,
    formData,
  };
};

export const signup = (formData) => {
  return {
    type: AUTH0_SIGNUP_REQUEST,
    formData,
  };
};

export const logout = () => {
  return {
    type: AUTH0_LOGOUT_REQUEST,
  };
};

export const auth0AuthenticationCallback = () => {
  return {
    type: AUTH0_CALLBACK_RESPONSE,
  };
};

export const auth0VerifyToken = () => {
  return {
    type: AUTH0_VERIFY_TOKEN_REQUEST,
  };
};

export const auth0ClearErrors = () => {
  return {
    type: AUTH0_CLEAR_ERRORS,
  };
};

export const forgotPassword = (formData) => {
  return {
    type: AUTH0_FORGOT_PASSWORD_REQUEST,
    formData,
  };
};
