import React from 'react';
import { useSelector } from 'react-redux';
import { BrowserRouter as Router, Switch, Redirect } from 'react-router-dom';
import NavigationBar from './components/NavigationBar';
import Footer from './components/Footer';
import PublicRoute from './components/routes/PublicRoute';
import PrivateRoute from './components/routes/PrivateRoute';
import Lobby from './pages/Lobby';
import Profile from './pages/Profile';
import Stables from './pages/Stables/Stables';
import Tracks from './pages/Tracks/Tracks';
import Signup from './components/SignUp';
import Login from './components/Login';
import CMSPage from './pages/Footer/CMSPage';
import GameSupport from './pages/Footer/GameSupport';
import Settings from './pages/Settings';
import AuthCallback from './pages/auth/AuthCallback';
import StableBuilder from './pages/Stables/StableBuilder';
import ForgotPassword from './components/ForgotPassword';
import NewStableEntrance from './pages/Stables/NewStableEntrance';
import './App.scss';

const App = () => {
  const isAuthenticated = useSelector((state) => state.account.authenticated);

  return (
    <Router>
      {isAuthenticated && <NavigationBar />}
      <Switch>
        <Redirect exact from="/" to="/login" />
        <PublicRoute restricted={true} component={Login} path='/login' exact />
        <PublicRoute restricted={true} component={Signup} path='/signup' exact />
        <PublicRoute restricted={true} component={ForgotPassword} path='/forgotpassword' exact />
        <PublicRoute restricted={true} component={AuthCallback} path='/authcallback' exact />

        <PrivateRoute component={Lobby} path='/lobby' exact />
        <PrivateRoute component={Tracks} path='/tracks' />
        <PrivateRoute component={NewStableEntrance} path='/stables/entergame' exact />
        <PrivateRoute component={StableBuilder} path='/stables/:mode/:stableId' exact />
        <PrivateRoute component={Stables} path='/stables'/>
        <PrivateRoute component={Profile} path='/profile' exact />
        <PrivateRoute component={Settings} path='/settings' exact />
        <PrivateRoute component={GameSupport} path='/gamesupport' exact />
        <PrivateRoute component={CMSPage} path='/(howtoplay|rules|about|termsofuse|privacy)' exact />
      </Switch>
      {isAuthenticated && <Footer />}
    </Router>
  );
};

export default App;
