import axios from 'axios';

export const PAGE_SIZE = 25;

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_ROOT,
});

export const sdApiRequest = (config) => {
  const {
    method,
    url,
    data,
    token,
  } = config;

  const headers = {
    'Content-Type': 'application/json',
  };

  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }

  const dataOrParams = ['GET', 'DELETE'].includes(method) ? 'params' : 'data';

  const onSuccess = (response) => {
    return response.data;
  };

  const onError = (error) => {
    return Promise.reject(error.response || error.message);
  };

  return axiosInstance({
    method,
    url,
    [dataOrParams]: data,
    headers,
  }).then(onSuccess)
    .catch(onError);
};
