import { put, takeEvery } from 'redux-saga/effects';
import { getRacecardById } from '../../api/RacecardsApi';
import {
  FETCH_RACECARD_BY_ID,
  fetchSingleRacecardSuccess,
  fetchRacecardFail,
} from '../actions/racecards';

export function* fetchRacecardById ({ id }) {
  try {
    const response = yield getRacecardById(id);

    yield put(fetchSingleRacecardSuccess(response));
  } catch (error) {
    yield put(fetchRacecardFail(error));
  }
}

export default function* racecardsSaga () {
  yield takeEvery(FETCH_RACECARD_BY_ID, fetchRacecardById);
}
