import React from 'react';
import {
  Col,
  Row,
} from 'react-bootstrap';
import { useSelector } from 'react-redux';
import * as _ from 'lodash';
import classes from './StablePointsProgress.module.scss';
import TrophyProgress from './TrophyProgress.js';
import ProgressBar from './ProgressBar.js';
import RacetrackProgressBar from './RacetrackProgress.js';
import { findPlayerLevel } from '../shared/StablePointTiers';
import { getUser } from '../redux/selectors/user';
import { thousandsSeparator } from '../shared/utility';

const StablePointsProgress = () => {
  const user = useSelector(getUser);
  const rank = _.get(user, 'rank');
  const stablePoints = _.get(user, 'stable_points');
  const playerLevel = findPlayerLevel(stablePoints === undefined ? 0 : stablePoints);

  return (
    <div className={classes.StablePointsProgress} >
      <Row className={classes.topRow} >
        <Col className={classes.contentCell}  >
          <Row className={classes.pointsWrapper}>
            <Col>
              <Row className={classes.stablePoints}>
                <Col>
                  <Row className={classes.pointTotal}>
                    {stablePoints && thousandsSeparator(stablePoints)}
                  </Row>
                  <Row className={classes.pointsLabel}>
                    STABLE POINTS
                  </Row>
                </Col>
              </Row>
              <Row className={classes.rank}>
                <span className={classes.userRank}>
                  {rank}
                </span>
                <span className={classes.rankText}>RANK</span>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col className={classes.contentCell} sm={4}>
          <div className={classes.trackWrapper}>
            <RacetrackProgressBar userProgress={playerLevel.distanceToLevel} />
          </div>
        </Col>
        <Col className={classes.contentCell} >
          <div className={classes.trophyWrapper}>
            <TrophyProgress img={playerLevel.src} />
          </div>
        </Col>
      </Row>
      <div className={classes.contentRow}>
        <div className={classes.contentCell}>
          <ProgressBar playerLevel={playerLevel} />
        </div>
      </div>
    </div>
  );
};

export default StablePointsProgress;
