import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Tab, Container, Row, Col, Nav } from 'react-bootstrap';
import _ from 'lodash';
import classNames from 'classnames/bind';

import { getGamesByDate, getGames } from '../../redux/selectors/games';
import moment from 'moment';
import classes from './TracksWidget.module.scss';
import TracksImage from '../../assets/images/nav_tracks.png';
import TrackWidgetHeader from './TrackWidgetHeader';
import TrackWidgetGameDetails from './TrackWidgetGameDetails';
import Loader from '../Loader';

const TracksWidget = () => {
  const c = classNames.bind(classes);
  const initFocus = useRef(null);

  const error = useSelector((state) => state.games.error);
  const gamesLoading = useSelector((state) => state.games.gamesLoading);
  const gamesByDate = useSelector(getGamesByDate);
  const games = useSelector(getGames);
  const gamesData = _.filter(games, (game) => game.race_date >= moment().format('YYYY-MM-DD'));
  const [tracksList, setTracksList] = useState(_.fill(Array(6), null));

  const dateList = _.map(
    _.range(0,6),
    (index) => {
      const startDate = moment().add(index, 'days').format('YYYY-MM-DD');

      return {
        startDate,
        endDate: startDate,
        active: false,
        labeldd: moment(startDate).format('ddd').toUpperCase(),
        labelDD: moment(startDate).format('DD'),
        month: moment().format('MMM').toUpperCase(),
        tracksList: _.pickBy(
          gamesByDate,
          (value, key) => key === startDate,
        )[startDate],
      };
    },
  );

  const allGames = _.map(
    _.transform(
      _.groupBy(gamesData, 'track.name'),
      (obj, v, k) => {
        return obj.push({
          track: k,
          games: v,
        });
      },
      [],
    ),
    (track) => <div key={`allTracks-${track.track}`}>
      <TrackWidgetHeader>
        {track.track}
      </TrackWidgetHeader>
      {_.map(
        track.games,
        (game) => (
          <TrackWidgetGameDetails
            game={game} key={`all-${game.id}`}
          />
        ),
      )}
    </div>,
  );

  useEffect(() => {
    setTracksList(
      _.map(
        _.map(dateList, 'startDate'),
        (startDate) =>
          _.pickBy(
            gamesByDate,
            (value, key) => key === startDate,
          )[startDate],
      ),
    );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gamesByDate]);

  return (
    <React.Fragment>
      {error && <p>Got ERROR: {error.message}</p>}
      <Row className={classes.titleRow}>
        <div>
          <img
            className={classes.navImage}
            src={TracksImage}
            alt='Tracks'
          />
          TRACKS
        </div>
      </Row>
      <Tab.Container className="justify-content-lg-center" defaultActiveKey="month">
        <Container>
          <Row>
            <Nav className={classes.trackNav} variant="dark">
              <Nav.Item key={'month'} autoFocus="True">
                <Nav.Link
                  className={classes.monthContainer}
                  eventKey={'month'}
                  key={'month'}
                  ref={initFocus}
                >
                  {dateList[0].month}
                </Nav.Link>
              </Nav.Item>
              { dateList.map((date, index) => (
                <Col className={classes.colDate} key={index}>
                  <Nav.Item key={date.key}>
                    <Nav.Link
                      className={classNames(classes.navLink, c({deadNavLink: _.isUndefined(date.tracksList)}))}
                      eventKey={index}
                      key={index}
                    >
                      <Row
                        className={classNames(
                          classes.labelddFilled, c({labeldd: _.isUndefined(date.tracksList)}))}>
                        {date.labeldd}
                      </Row>
                      <Row
                        className={classNames(
                          classes.labelDDFilled, c({labelDD: _.isUndefined(date.tracksList)}))}>
                        {date.labelDD}
                      </Row>
                    </Nav.Link>
                  </Nav.Item>
                </Col>
              ))}
            </Nav>
          </Row>
          {gamesLoading ?
            <Row>
              <Col>
                <Loader />
              </Col>
            </Row>
            :
            <Tab.Content className={classes.tabContent}>
              <Tab.Pane eventKey={'month'} key={'month'}>
                {allGames}
              </Tab.Pane>
              {tracksList.map((date, index) => (
                <Tab.Pane eventKey={index} key={index}>
                  {_.isNil(date) ?
                    <p className={classes.noGame}>No Games Available</p>
                    :
                    <div>
                      {date.map(
                        (track, idx) => (
                          <div key={idx}>
                            <TrackWidgetHeader>
                              {track.track}
                            </TrackWidgetHeader>
                            {_.map(
                              track.games,
                              (game) => (
                                <TrackWidgetGameDetails
                                  game={game} key={game.id}
                                />
                              ),
                            )}
                          </div>
                        ),
                      )}
                    </div>
                  }
                </Tab.Pane>
              ))}
            </Tab.Content>
          }
        </Container>
      </Tab.Container>
    </React.Fragment>
  );
};

export default TracksWidget;
