import * as Actions from '../actions/Auth0';

export const initialState = {
  profile: null,
  authenticated: false,
  idToken: null,
  accessToken: null,
  expirationDate: null,
  callbackPending: false,
  callbackError: null,
  loginPending: false,
  loginError: null,
  signupPending: false,
  signupError: null,
  logoutError: null,
  verificationPending: false,
  verificationError: null,
  forgotPasswordPending: false,
  forgotPasswordError: null,
  forgotPasswordMessage: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case Actions.AUTH0_SIGNUP_REQUEST:
      return {
        ...state,
        signupPending: true,
      };
    case Actions.AUTH0_SIGNUP_SUCCESS:
      return {
        ...state,
        signupPending: false,
        signupError: initialState.signupError,
      };
    case Actions.AUTH0_SIGNUP_ERROR:
      return {
        ...state,
        signupPending: false,
        signupError: action.error,
      };
    case Actions.AUTH0_LOGIN_REQUEST:
      return {
        ...state,
        loginPending: true,
      };
    case Actions.AUTH0_LOGIN_ERROR:
      return {
        ...state,
        loginPending: false,
        loginError: action.error,
        authenticated: false,
      };
    case Actions.AUTH0_LOGOUT_ERROR:
      return {
        ...state,
        authenticated: false,
        logoutError: action.error,
      };
    case Actions.AUTH0_CALLBACK_RESPONSE:
      return {
        ...state,
        callbackPending: true,
      };
    case Actions.AUTH0_LOGIN_SUCCESS:
      return {
        ...state,
        loginPending: false,
        callbackPending: false,
        profile: action.profile,
        idToken: action.idToken,
        accessToken: action.accessToken,
        expirationDate: action.expirationDate,
        authenticated: action.authenticated,
      };
    case Actions.AUTH0_CALLBACK_RESPONSE_ERROR:
      return {
        ...state,
        callbackPending: false,
        callbackError: action.error,
        authenticated: false,
      };
    case Actions.AUTH0_CLEAR_ERRORS:
      return {
        ...state,
        callbackError: initialState.callbackError,
        loginError: initialState.loginError,
        signupError: initialState.signupError,
        logoutError: initialState.logoutError,
        verificationError: initialState.verificationError,
      };
    case Actions.AUTH0_VERIFY_TOKEN_REQUEST:
      return {
        ...state,
        verificationPending: true,
      };
    case Actions.AUTH0_VERIFY_TOKEN_SUCCESS:
      return {
        ...state,
        verificationPending: false,
        idToken: action.idToken,
        accessToken: action.accessToken,
        expirationDate: action.expirationDate,
        authenticated: action.authenticated,
      };
    case Actions.AUTH0_VERIFY_TOKEN_ERROR:
      return {
        ...state,
        verificationPending: false,
        verificationError: action.error,
        idToken: initialState.idToken,
        accessToken: initialState.accessToken,
        expirationDate: initialState.expirationDate,
        authenticated: false,
      };
    case Actions.AUTH0_FORGOT_PASSWORD_REQUEST:
      return {
        ...state,
        forgotPasswordPending: true,
      };
    case Actions.AUTH0_FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        forgotPasswordPending: false,
        forgotPasswordMessage: action.message,
      };
    case Actions.AUTH0_FORGOT_PASSWORD_ERROR:
      return {
        ...state,
        forgotPasswordPending: false,
        forgotPasswordError: action.error,
      };
    default:
      return state;
  }
};
