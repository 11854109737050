import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector  } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroller';
import * as _ from 'lodash';
import {
  Container,
  Row,
  Col,
} from 'react-bootstrap';
import classNames from 'classnames/bind';
import classes from './SmallLeaderBoard.module.scss';
import MyStablesImage from '../../assets/images/nav_mystables.png';
import { fetchLeaderBoard, clearLeaderBoard } from '../../redux/actions/leaderBoard';
import { getUser } from '../../redux/selectors/user';

const SmallLeaderBoard = ({ stable }) => {
  const c = classNames.bind(classes);
  const dispatch = useDispatch();
  const { isLoading, hasMoreItems, stables } = useSelector((state) => ({
    isLoading: state.leaderBoard.isLoading,
    hasMoreItems: state.leaderBoard.hasMoreItems,
    stables: state.leaderBoard.stables,
  }));
  const user = useSelector(getUser);
  const stableName = _.get(user, 'profile.stable_name');

  const gameId = _.get(stable, 'game.id');
  const rank = _.get(stable, 'stable.rank');
  const stablePoints = _.get(stable, 'stable.score');
  const gameStarted = _.get(stable, 'game.timeUntilStart') === 0;
  const stableId = _.get(stable, 'stableId');

  const loadItems = useCallback(
    (page) => {
      if (page > 1) {
        dispatch(fetchLeaderBoard({ gameId, page }));
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[dispatch],
  );

  useEffect(() => {
    dispatch(clearLeaderBoard());
    dispatch(fetchLeaderBoard({ gameId, page: 1 }));
  }, [dispatch, gameId]);

  const Leaderboard = ({ payload = {} }) => {

    return (
      <Row className={classNames(classes.userRow , 'mx-0', c({myStable: payload.stable === stableId}))}>
        <Col sm={2} className={classNames(classes.rank, c({topThree: payload.stable === stableId}))}>
          {payload.rank}
        </Col>
        <Col sm={5} className={classes.stableName}>
          {payload.stable_name}
        </Col>
        <Col sm={{ span: 3, offset: 1 }} className={`${classes.boardPoints} pl-3`}>
          {payload.score}
        </Col>
      </Row>
    );
  };

  return (
    <Container className={classNames(classes.leaderboard, 'pt-3', c({notStarted: gameStarted === false}))}>
      <Col sm={12}>
        <div className={classNames(classes.divWrapper, 'px-3', c({noBackground: gameStarted === true}))}>
          <Row className={`${classes.myStableInfo} d-flex align-items-center flex-nowrap`}>
            <Col sm={2} className={classNames(classes.myRank, c({blankRank: gameStarted === false}))}>
              {gameStarted ? rank : '--'}
            </Col>
            <Col sm={2} className="px-2">
              <img className={classes.image} src={MyStablesImage} alt="horse" />
            </Col>
            <Col sm={4} className={`${classes.myStableName} text-center px-2`}>
              {stableName}
            </Col>
            <Col sm={4} className={`${classes.myPoints} pl-3`}>
              {gameStarted ? stablePoints : '---'}
            </Col>
          </Row>
        </div>
      </Col>
      {gameStarted &&
        <Row className={`${classes.tableHeadings} mx-3 mt-1`}>
          <Col sm={2} className="pl-4">Rank</Col>
          <Col sm={5}>Stable</Col>
          <Col sm={{span: 3, offset: 1}} className="pl-3">Total Points</Col>
        </Row>
      }
      <Row className={`${classes.boardObjects} mx-3`}>
        {gameStarted ?
          <InfiniteScroll
            pageStart={0}
            loadMore={loadItems}
            hasMore={!isLoading && hasMoreItems}
            loader={<div key={0}>Loading ...</div>}
            useWindow={false}
            style={{width: '100%', height: '18.75rem'}}
          >
            {_.map(
              stables,
              (stable) => <Leaderboard key={`leaderboardItem-${stable.id}`} payload={stable} />,
            )}
          </InfiniteScroll>
          :
          <Col sm={12} className={`${classes.futureContainer} text-center`}>
            <Row className={`${classes.futureGameTxt} mt-3 text-center justify-content-center`}>
              YOU CAN VIEW THIS LEADERBOARD <br /> AFTER THE GAME STARTS IN <br/>
            </Row>
            <Row className={`${classes.futureGameDays} text-center justify-content-center mb-3`}>
              3 DAYS
            </Row>
          </Col>
        }
      </Row>
    </Container>
  );
};

export default SmallLeaderBoard;
