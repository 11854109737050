import { all, call, spawn } from 'redux-saga/effects';
import tracksSaga from './tracks';
import gamesSaga from './games';
import accountSaga from './Auth0';
import userSaga from './user';
import globalLeaderBoardSaga from './globalLeaderBoard';
import runnersSaga from './runners';
import stableSaga from './stableBuilder';
import userStablesSaga from './userStables';
import racecardsSaga from './racecards';
import leaderBoardSaga from './leaderBoard';

export function* rootSaga () {
  const sagas = [
    tracksSaga,
    gamesSaga,
    racecardsSaga,
    accountSaga,
    userSaga,
    globalLeaderBoardSaga,
    runnersSaga,
    stableSaga,
    userStablesSaga,
    leaderBoardSaga,
  ];
  yield all(sagas.map((saga) =>
    spawn(function* () {
      while (true) {
        try {
          yield call(saga);
          break;
        } catch (error) {
          console.log('Root Saga ', error);
        }
      }
    })),
  );
}
