import React from 'react';
import { Modal } from 'react-bootstrap';
import classes from './SpeedValueRiskModal.module.scss';

function SpeedValueRiskModal ({ show, onHide }) {

  return (
    <Modal 
      size="xl" 
      show={show} 
      onHide={onHide} 
      centered
    >
      <Modal.Header
        closeButton
      > 
        <span className={classes.infoHeader}> SPEED / VALUE / RISK </span>
      </Modal.Header>
      <Modal.Body 
        className={classes.infoBody}
      >
        <p className={classes.subTitle}> Speed Rank </p>
        <p>
          Speed Rank is based on a three race average of multiple industry-delivered speed ratings (take from the last 
          three races). Horses in each race are ranked from fastest to slowest, with 1 being the fastest.
        </p>
        <p>
          Note: A speed rating represents how fast a horse ran in a past race, adjusted for track condition, distance 
          and surface. A higher speed figure represents a faster relative time on the same surface and condition.
        </p>
        <p className={classes.subTitle}> Value Rank </p>
        <p>
          Value rank is based on a simple average of its StableDuel points earned over the last five races compared to 
          the salary for that horse on that day (dollars per StableDuel point). There is no adjustment made for class. 
          Horses in each race are ranked from best to worst value, with 1 being the best.
        </p>
        <p className={classes.subTitle}> Risk </p>
        <p>
          Risk rating is based on an estimate of the number of lengths it will be beaten by in the upcoming race, 
          based on past performances, adjusted for class. A high risk indicates the likelihood that the given horse 
          will be beaten by X or more lengths.
        </p>
      </Modal.Body>
    </Modal>
  );
}
export default SpeedValueRiskModal;
