import React, {useState} from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Row,
  Col,
} from 'react-bootstrap';
import {
  Form,
  Field,
} from 'react-final-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';

import Button from '../Button';
import { Email } from './Input';
import classes from './ForgotPasswordForm.module.scss';
import {
  composeValidators,
  required,
} from './Validators';
import LinkBtn from '../LinkBtn';
import { forgotPassword } from '../../redux/actions/Auth0';

export default function ForgotPasswordForm () {
  const dispatch = useDispatch();

  const [resetEmail, setResetEmail] = useState(false);

  const toggleEmailSubmitted = () => {
    setResetEmail(true);
  };

  const onSubmit = (params) => {
    dispatch(forgotPassword(params));
    toggleEmailSubmitted();
  };

  return(
    <React.Fragment>
      <Form onSubmit={onSubmit}>
        {({handleSubmit}) => <form onSubmit={handleSubmit} className={classes.wrapper}>
          {!resetEmail ?
            <React.Fragment>
              <Row className={`${classes.passTxt} justify-content-sm-center`}>
                <Col md={10}>
                  <h4 className={classes.greenTxt}> Reset Password </h4>
                  <div>{"No Worries! We'll email you instructions to reset your password."}</div>
                </Col>
              </Row>
              <Row className={`${classes.rowWrapper} justify-content-sm-center`}>
                <Col md={6} className={classes.col}>
                  <Field name='email' validate={composeValidators(required)}>
                    {({input, meta})=>
                      <Email
                        name={input.name}
                        placeholder='Email'
                        onBlur={input.onBlur}
                        onChange={input.onChange}
                        onFocus={input.onFocus}
                        value={input.value}
                        error={meta.touched && meta.error}
                      />
                    }
                  </Field>
                </Col>
              </Row>
              <Row className="justify-content-lg-center mt-3">
                <Col lg="6">
                  <Button type='submit' styling='outline-large'>
                    Reset Password
                  </Button>
                </Col>
              </Row>
              <Row className={`${classes.fpRow} justify-content-lg-center mt-4`}>
                <Col
                  lg={5}
                  className={classes.fpIcon}>
                  <FontAwesomeIcon icon={faChevronLeft} size="2x" color="white"/>
                  <Link className={classes.linkTxt} to="/login"> {'Go Back'} </Link>
                </Col>
              </Row>
            </React.Fragment>
            :
            <React.Fragment>
              <Row className={`${classes.passTxt} justify-content-sm-center mb-3`}>
                <Col md={10}>
                  <h4 className={classes.greenTxt}> Thanks! </h4>
                  <div>We sent you an email to reset your password.</div>
                </Col>
              </Row>
              <Row className={`${classes.passTxt} justify-content-sm-center mb-5`}>
                <Col md={10}>
                  <div className={classes.greenTxt}>{"Don't see the email?"}</div>
                  <button className={classes.resendEmail} onClick={handleSubmit}>Resend email now.</button>
                </Col>
              </Row>
              <LinkBtn
                className={classes.returnLogin}
                label="RETURN TO LOGIN"
                path="/login"
                styling={'whiteOutlineLarge'} />
            </React.Fragment>
          }
        </form>}
      </Form>
    </React.Fragment>
  );
}
