import {
  getUserStablesWithGameAndRaceCard,
  userStablesNotStartedSelector,
  userStablesStartedSelector,
} from '../../redux/selectors/userStables';

export const LOBBY = 'Lobby';
export const TODAY = 'Today';
export const FUTURE = 'Future';

export const MyStableTypes = {
  Lobby: {
    type: LOBBY,
    title: '',
    selector: getUserStablesWithGameAndRaceCard,
  },
  Today: {
    type: TODAY,
    title: 'TODAY',
    selector: userStablesStartedSelector,
  },
  Future: {
    type: FUTURE,
    title: 'FUTURE',
    selector: userStablesNotStartedSelector,
  },
};
