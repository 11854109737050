import React, { useState } from 'react';
import {Row, Col} from 'react-bootstrap';
import classes from './RaceCardRow.module.scss';
import classNames from 'classnames/bind';
import HorseDetailsModal from '../modals/HorseDetailsModal';
import SpeedValueRiskModal from '../modals/SpeedValueRiskModal';

const RaceCardRow = ({ runner, userRunner, updateRunners, disabled }) => {
  const [modalDetailShow, setModalDetailShow] = useState(false);
  const [modalInfoShow, setModalInfoShow] = useState(false);

  const isDisabled = (disabled && userRunner.length === 0) || runner.scratched || runner.disqualified;
  const bindClasses = classNames.bind(classes);
  const selectClasses = bindClasses({
    selected: userRunner.length > 0,
    disabled: isDisabled,
    scratched: runner.scratched || runner.disqualified,
  });

  const toggleInfo = () => {
    setModalInfoShow(!modalInfoShow);
    setModalDetailShow(!modalDetailShow);
  };

  return (
    <div>
      <Row key={runner.id} className={classNames(classes.raceCardRow, selectClasses)}>
        {isDisabled && <div className={classes.disabledOverlay} />}
        {(runner.scratched || runner.disqualified) && <div className={classes.scratchedOverlay}>SCRATCH</div>}
        <Col className={classes.raceCardInfo} onClick={() => setModalDetailShow(true)} md lg={11}>
          <Row>
            <Col className={classNames(classes.postPosition, classes[`cloth_color_${runner.post_position}`])} lg={1}>
              <div className={classes.postText}>
                <div className={classes.postNumber}>
                  {runner.post_position}
                </div>
                {`R${runner.race_number}`}
              </div>
            </Col>
            <Col className={classes.horseNameWrapper} lg={5}>
              <div className={classes.horseName}>
                {runner.name}
              </div>
            </Col>
            <Col xs={7} lg={4} >
              <Row>
                <Col className={classes.trainerBlock} xs={2} md={4} lg={3}>Jockey:</Col>
                <Col className={classes.trainerJockey}>{runner.jockey}</Col>
              </Row>
              <Row>
                <Col className={classes.trainerBlock} xs={2} md={4} lg={3}>Trainer:</Col>
                <Col className={classes.trainerJockey}>{runner.trainer}</Col>
              </Row>
            </Col>
            <Col className={classes.salaryBlock} xs={{span: 2, offset: 3}} lg={{span: 2, offset: 0}}>
              <Row >{runner.odds}</Row>
              <Row className={classes.salary}>${(runner.salary).toLocaleString()}</Row>
            </Col>
          </Row>
        </Col>
        <Col className={classes.selectionWrapper} onClick={() => updateRunners(runner)} md={12} lg={1}>
          <div className={classNames(classes.selectionStatus, selectClasses)} />
        </Col>
      </Row>
      {modalDetailShow && <HorseDetailsModal
        show={modalDetailShow}
        onHide={() => setModalDetailShow(false)}
        toggle={toggleInfo}
        runnerId={runner.id}
      />}

      {modalInfoShow && <SpeedValueRiskModal
        show={modalInfoShow}
        onHide={toggleInfo}
      />}
    </div>
  );
};

export default RaceCardRow;
