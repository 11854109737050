export const ENTER_GAME = 'SB/ENTER_GAME';
export const ENTER_GAME_SUCCESS = 'SB/ENTER_GAME_SUCCESS';
export const ENTER_GAME_FAIL = 'SB/ENTER_GAME_FAIL';
export const UPDATE_RUNNERS = 'SB/UPDATE_RUNNERS';
export const UPDATE_RUNNERS_SUCCESS = 'SB/UPDATE_RUNNERS_SUCCESS';
export const UPDATE_RUNNERS_FAIL = 'SB/UPDATE_RUNNERS_FAIL';
export const EDIT_STABLE = 'SB/EDIT_STABLE';
export const EDIT_STABLE_SUCCESS = 'SB/EDIT_STABLE_SUCCESS';
export const EDIT_STABLE_FAIL = 'SB/EDIT_STABLE_FAIL';
export const SUBMIT_STABLE = 'SB/SUBMIT_STABLE';
export const SUBMIT_STABLE_SUCCESS = 'SB/SUBMIT_STABLE_SUCCESS';
export const SUBMIT_STABLE_FAIL = 'SB/SUBMIT_STABLE_FAIL';
export const DELETE_STABLE = 'SB/DELETE_STABLE';
export const DELETE_STABLE_SUCCESS = 'SB/DELETE_STABLE_SUCCESS';
export const DELETE_STABLE_FAIL = 'SB/DELETE_STABLE_FAIL';
export const CLEAR_STABLE_BUILDER_STATE = 'RUNNERS/CLEAR_STABLE_BUILDER_STATE';

export const enterGame = ({gameId, userId, history}) => ({
  type: ENTER_GAME,
  gameId,
  userId,
  history,
});

export const enterGameSuccess = (stable) => ({
  type: ENTER_GAME_SUCCESS,
  stable,
});

export const enterGameFail = (error) => ({
  type: ENTER_GAME_FAIL,
  error,
});

export const updateRunners = ({id, runners}) => ({
  type: UPDATE_RUNNERS,
  id,
  runners,
});

export const updateRunnersSuccess = (stable) => ({
  type: UPDATE_RUNNERS_SUCCESS,
  stable,
});

export const updateRunnersFail = (error) => ({
  type: UPDATE_RUNNERS_FAIL,
  error,
});

export const editStable = ({id, mode, history}) => ({
  type: EDIT_STABLE,
  id,
  mode,
  history,
});

export const editStableSuccess = ({stable, isLocallyEditing}) => ({
  type: EDIT_STABLE_SUCCESS,
  stable,
  isLocallyEditing,
});

export const editStableFail = (error) => ({
  type: EDIT_STABLE_FAIL,
  error,
});

export const submitStable = ({id, history}) => ({
  type: SUBMIT_STABLE,
  id,
  history,
});

export const submitStableSuccess = (stable) => ({
  type: SUBMIT_STABLE_SUCCESS,
  stable,
});

export const submitStableFail = (error) => ({
  type: SUBMIT_STABLE_FAIL,
  error,
});

export const clearStableBuilder= () => ({
  type: CLEAR_STABLE_BUILDER_STATE,
});

export const deleteStable = ({id, history}) => ({
  type: DELETE_STABLE,
  id,
  history,
});

export const deleteStableSuccess = (id) => ({
  type: DELETE_STABLE_SUCCESS,
  id,
});

export const deleteStableFail = (error) => ({
  type: DELETE_STABLE_FAIL,
  error,
});
