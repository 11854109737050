export const FETCH_GAMES_UPCOMING = 'GAMES/FETCH_UPCOMING';
export const FETCH_GAMES_BY_DATES = 'GAMES/FETCH_BY_DATE';
export const FETCH_GAMES_DATA_MULTIPLE_SUCCESS = 'GAMES/FETCH_MULTIPLE_DATA_SUCCESS';
export const FETCH_GAMES_DATA_FAIL = 'GAMES/FETCH_DATA_FAIL';
export const FETCH_GAME_BY_ID = 'GAMES/FETCH_BY_ID';
export const FETCH_GAME_DATA_SINGLE_SUCCESS = 'GAMES/FETCH_SINGLE_DATA_SUCCESS';

export const fetchUpcomingGames = () => ({
  type: FETCH_GAMES_UPCOMING,
});

export const fetchGamesByDates = (params) => ({
  type: FETCH_GAMES_BY_DATES,
  params,
});

export const fetchMultipleGamesSuccess = (data) => ({
  type: FETCH_GAMES_DATA_MULTIPLE_SUCCESS,
  data,
});

export const fetchGamesFail = (error) => ({
  type: FETCH_GAMES_DATA_FAIL,
  error,
});

export const fetchGameByID = (id) => ({
  type: FETCH_GAME_BY_ID,
  id,
});

export const fetchSingleGameSuccess = (data) => ({
  type: FETCH_GAME_DATA_SINGLE_SUCCESS,
  data,
});
