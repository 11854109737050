import React, { useState, useEffect } from 'react';
import classes from './ProgressBar.module.scss';
import classNames from 'classnames/bind';
import { stablePointTiers } from '../shared/StablePointTiers';
import { RunningHorse } from './RunningHorse';

const TrophyComponent = ({ tier, playerPoints }) => {
  let trophyClasses = classNames.bind(classes);

  return <img className={classNames(
    classes.trophy,
    trophyClasses({ notAchieved: playerPoints < tier.threshhold }),
    classes[tier.styleClass],
  )}
  src={tier.postSrc}
  alt={(tier.styleClass).toUpper}
  />;
};

const ProgressBar = ({
  playerLevel = {},
}) => {
  const tiers = stablePointTiers(); 
  const finalProgressBar = Math.min(98, playerLevel.userProgressPct);
  const finalHorseOffset = Math.max(6, finalProgressBar); 
  const [ currentProgress, setCurrentProgress ] = useState(0);
  const [ horseOffset, setHorseOffset ] = useState(0);
  const frames = 25;
  const horseIncrement = finalHorseOffset/frames;
  const progressIncrement = finalProgressBar/frames;

  useEffect(() => {
    let timer;
    if (currentProgress < finalProgressBar) {
      timer = setTimeout(() => {
        setCurrentProgress( currentProgress + progressIncrement );
        setHorseOffset( horseOffset + horseIncrement );
      }, 100);
    }

    return function cleanup () {
      clearTimeout(timer);
    };
  });

  return (
    <div className={classes.progressWrapper}>
      <div className={classes.progressBar}>
        <RunningHorse currentPosition={horseOffset} finalPosition={finalHorseOffset} />
        <div className={classes.progressDiv} >
          <div style={{ width: currentProgress + '%' }} className={classes.progress} />
        </div>
      </div>
      <div className={classes.trophyRow}>
        {tiers.map((tier, index) => 
          <TrophyComponent key={`ProgressTier${index}`} tier={tier} playerPoints={playerLevel.stablePoints} />,
        )}
      </div>
    </div>
  );
};

export default ProgressBar;
