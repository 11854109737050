import React from 'react';
import { PropTypes } from 'prop-types';
import { NavLink } from 'react-router-dom';
import classes from './NavigationItem.module.scss';

const NavigationItem = ({ link, exact, setActiveClass, image, width, height, children }) => {
  return (
    <NavLink
      data-testid={children}
      className={classes.navItem}
      to={link}
      exact={exact}
      activeClassName={setActiveClass ? classes.navItemActive : ''}>
      <img className={classes.navImage}
        src={image}
        width={width}
        height={height}
        alt={children} />
      {children}
    </NavLink>
  );
};

NavigationItem.propTypes = {
  link: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  exact: PropTypes.bool,
  children: PropTypes.string,
  setActiveClass: PropTypes.bool,
};

export default NavigationItem;
