import React from 'react';

const InfoCircle = ({ infoLabel, infoValue }) => (
  <svg width="60" height="60">
    <circle cx="30" cy="30" r="30" fill="#dddddd" />
    <text x="50%" y="30%" textAnchor="middle" fill="black" fontSize="10px" fontWeight="bold" dy=".3em">
      {infoLabel}
    </text>
    <text x="50%" y="60%" textAnchor="middle" fill="black" fontSize="24px" fontWeight="bold" dy=".3em">
      {infoValue}
    </text>
  </svg>
);

export default InfoCircle;
