import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import { Redirect } from 'react-router';
import * as _ from 'lodash';
import { enterGame } from '../../redux/actions/stableBuilder';
import Loader from '../../components/Loader';
import { userIdSelector } from '../../redux/selectors/user';

const NewStableEntrance = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const userId = useSelector(userIdSelector);
  const { state } = useLocation();

  useEffect(() => {
    const gameId = _.get(state, 'gameId');
    if (gameId && userId) {
      dispatch(enterGame({ userId, gameId, history }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  if (_.isUndefined(_.get(state, 'fromTracks'))) {
    return <Redirect to="/Tracks" />;
  }

  return (
    <div
      data-testid='new-stable-entrance'
      className={'App-content'}>
      <Loader />
    </div>
  );
};
export default NewStableEntrance;
