import React, { useState }from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import _ from 'lodash';
import {
  Col,
  Row,
  Container,
} from 'react-bootstrap';
import { useSelector } from 'react-redux';
import classes from './Stables.module.scss';
import SDLogoWithTag from '../../assets/images/stableduel-logo-with-tag.png';
import LinkBtn from '../../components/LinkBtn';
import MyStablesWidget from '../../components/myStables/MyStablesWidget';
import { groupStablesByTrack } from '../../shared/stableUtils';
import { getUserStablesLoading } from '../../redux/selectors/userStables';
import Loader from '../../components/Loader';
import { MyStableTypes } from '../../components/myStables/MyStableTypes';
import MyStablesHorseList from '../../components/MyStablesHorseList';
import SmallLeaderBoard from '../../components/MyStablesWidgets/SmallLeaderBoard';
import { deleteStable } from '../../redux/actions/stableBuilder';

const findFirstStable = (stable) => {
  let firstStable = [];
  for (const key in stable) {
    firstStable = stable[key];
    break;
  }

  return _.head(firstStable, 0);
};

const Stables = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const userStablesLoading = useSelector(getUserStablesLoading);
  const [selectedStable, setSelectedStable] = useState(null);

  const startedStables = useSelector(MyStableTypes.Today.selector);
  const notStartedStables = useSelector(MyStableTypes.Future.selector);

  const groupedStartedStable = groupStablesByTrack(startedStables);
  const groupedNotStartedStable = groupStablesByTrack(notStartedStables);

  const defaultSelection =
    _.isEmpty(groupedStartedStable) ?
      findFirstStable(groupedNotStartedStable) : findFirstStable(groupedStartedStable);

  const todayStableHeight = _.isEmpty(groupedNotStartedStable) ? 'MaxHeight': '';
  const futureStableHeight = _.isEmpty(groupedStartedStable) ? 'MaxHeight': '';
  const gameId = selectedStable ? _.get(selectedStable, 'game.id') : _.get(defaultSelection, 'game.id');
  const updateSelectedStable = (stable) => {
    setSelectedStable(stable);
  };

  const onDeleteStable = () => {
    const params = {
      id: _.get(selectedStable, 'stableId'),
      history,
    };
    dispatch(deleteStable(params));
    const defaultSelection =
      _.isEmpty(groupedStartedStable) ?
        findFirstStable(groupedNotStartedStable) : findFirstStable(groupedStartedStable);
    setSelectedStable(defaultSelection);
  };

  return (
    <Container fluid data-testid='stables' className={classes.myStables}>
      <Row>
        <Col className={classes.contentCell} lg>
          <Row className={classes.todayFutureContainer}>
            { userStablesLoading ?
              <Loader /> :
              <Col className='p-0'>
                { !_.isEmpty(groupedStartedStable) &&
                  <MyStablesWidget
                    stableWidgetType={MyStableTypes.Today}
                    userStables={groupedStartedStable}
                    updateSelectedStable={updateSelectedStable}
                    selectedStableId=
                      {selectedStable ? selectedStable.stableId : defaultSelection.stableId}
                    height={todayStableHeight}/> }
                { !_.isEmpty(groupedNotStartedStable) &&
                  <MyStablesWidget
                    stableWidgetType={MyStableTypes.Future}
                    userStables={groupedNotStartedStable}
                    updateSelectedStable={updateSelectedStable}
                    selectedStableId=
                      {selectedStable ? selectedStable.stableId : defaultSelection.stableId}
                    height={futureStableHeight}/> }
              </Col>
            }
          </Row>
          <Row className={`${classes.newGameBtn} align-self-end`}>
            <LinkBtn
              label='+ NEW GAME'
              path='/tracks'
              styling='greenVerySkinny'
            />
          </Row>
        </Col>
        <Col className={classes.contentCell} lg>
          { userStablesLoading ?
            <Loader /> :
            <MyStablesHorseList
              stable={selectedStable ? selectedStable : defaultSelection}
              onDeleteStable={onDeleteStable}
            />
          }
        </Col>
        <Col className={classes.contentCell} lg>
          <Row className={classes.sdLogoRow}>
            <img
              className={classes.sdLogoTag}
              src={SDLogoWithTag}
              alt="Stable Duel: Play Race Win"
            />
          </Row>
          <Row>
            { userStablesLoading ?
              <Loader /> :
              gameId &&
                <SmallLeaderBoard
                  stable={selectedStable ? selectedStable : defaultSelection}
                />
            }
          </Row>
        </Col>
      </Row>
    </Container>
  );
};
export default Stables;
