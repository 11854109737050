export const FETCH_RUNNER = 'RUNNERS/FETCH_RUNNER';
export const FETCH_RUNNER_SUCCESS = 'RUNNERS/FETCH_RUNNER_SUCCESS';
export const FETCH_RUNNER_FAIL = 'RUNNERS/FETCH_RUNNER_FAIL';
export const CLEAR_RUNNER = 'RUNNERS/CLEAR_RUNNER';

export const fetchRunner = (id) => ({
  type: FETCH_RUNNER,
  id,
});

export const fetchRunnerSuccess = (data) => ({
  type: FETCH_RUNNER_SUCCESS,
  data,
});

export const fetchRunnerFail = (error) => ({
  type: FETCH_RUNNER_FAIL,
  error,
});

export const clearRunner = () => ({
  type: CLEAR_RUNNER,
});
