import React from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';

import { getUserStablesForGame } from '../../redux/selectors/userStables';

import LinkBtn from '../LinkBtn';

import {
  GameState,
  getGameState,
  canJoinGame,
  isStarted,
  getEntryFee,
} from '../../shared/gameUtils.js';

const EnterGameBtn = ({ styling, game, fromTracks = false }) => {
  const userStablesForGame = useSelector((state) => getUserStablesForGame(state, game));
  const entryFee = getEntryFee(game);
  let enterBtnTxt = `ENTER - ${entryFee}`;
  let btnDisabled = false;
  let path = {
    pathname: '/stables/entergame',
    state: {
      gameId: _.get(game, 'id'),
      fromTracks,
    },
  };

  if(!canJoinGame(game, userStablesForGame.length) &&
    userStablesForGame.length) {
    enterBtnTxt = 'View Stable';
    path = `/stables`;
  } else if (isStarted(game) === true) {
    enterBtnTxt = getGameState(game) === GameState.FINISHED ? 'Game Finished' : 'Game Closed';
    btnDisabled = true;
  }

  return (
    <LinkBtn
      label={enterBtnTxt}
      path={path}
      styling={styling ? styling : 'greenSkinny'}
      disabled={btnDisabled}
    />
  );
};

export default EnterGameBtn;
