import React, {useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Row,
  Col,
} from 'react-bootstrap';
import { 
  Form,
  Field,
} from 'react-final-form';

import Button from '../../components/Button';
import Loader from '../../components/Loader';
import TextInput, { Email, Password, Birthdate, Countries } from './Input';
import classes from './SignupForm.module.scss';
import {
  composeValidators,
  required,
  email,
  maxLength,
  minLength,
  passwordConditions,
  zipcode,
} from './Validators';

import { signup } from '../../redux/actions/Auth0';

export default function SignupForm () {
  const [passwordShown, setPasswordShown] = useState(false);
  const togglePasswordVisiblity = () => {
    setPasswordShown(!passwordShown);
  };

  const dispatch = useDispatch();
  const signupError = useSelector((state) => state.account.signupError);
  const signupPending = useSelector((state) => state.account.signupPending);

  const onSubmit = (params) => {
    params.name = params.given_name + ' ' + params.family_name;
    dispatch(signup(params));
  };

  return(
    <Form onSubmit={onSubmit} validate={(values) => {
      const errorsObj = {};
      /* Password match validation */
      if (values.confirmPassword && values.password !== values.confirmPassword) {
        errorsObj.confirmPassword = 'Passwords must match';
      }

      return errorsObj;
    }}>
      {({handleSubmit}) => <form onSubmit={handleSubmit} className={classes.wrapper}>
        <Row className={`${classes.rowWrapper} justify-content-md-center`}>
          <Col className={classes.col}>
            <Field name="email" validate={composeValidators(required, email)}>
              {({input, meta})=>
                <Email
                  name={input.name}
                  placeholder="Email"
                  onBlur={input.onBlur}
                  onChange={input.onChange}
                  onFocus={input.onFocus}
                  value={input.value}
                  error={meta.touched && meta.error}
                />
              }
            </Field>
          </Col>
        </Row>
        <Row className={classes.rowWrapper}>
          <Col md={6} className={classes.col}>
            <Field name="given_name" validate={required}>
              {({input, meta}) => (
                <TextInput
                  name={input.name}
                  placeholder="First Name"
                  onBlur={input.onBlur}
                  onChange={input.onChange}
                  onFocus={input.onFocus}
                  value={input.value}
                  error={meta.touched && meta.error}
                />
              )}
            </Field>
          </Col>
          <Col md={6} className={classes.col}>
            <Field name="family_name" validate={required}>
              {({input, meta}) => (
                <TextInput
                  name={input.name}
                  placeholder="Last Name"
                  onBlur={input.onBlur}
                  onChange={input.onChange}
                  onFocus={input.onFocus}
                  value={input.value}

                  error={meta.touched && meta.error}
                />
              )}
            </Field>
          </Col>
        </Row>
        <Row className={classes.rowWrapper}>
          <Col className={classes.col}>
            <Field name="password" validate={
              composeValidators(required, minLength(8), maxLength(20), passwordConditions)}>
              {({input, meta}) => (
                <div>
                  <Password
                    name={input.name}
                    placeholder="Password"
                    onBlur={input.onBlur}
                    onChange={input.onChange}
                    onFocus={input.onFocus}
                    value={input.value}
                    error={meta.touched && meta.error}
                    type={passwordShown ? 'text' : 'password'}
                  />
                  <i onClick={togglePasswordVisiblity}>{passwordShown ? 'Hide' : 'Show'}</i>
                </div>
              )}
            </Field>
          </Col>
          <Col className={classes.col}>
            <Field name="confirmPassword" validate={required}>
              {({input, meta}) => (
                <div>
                  <Password
                    name={input.name}
                    placeholder="Confirm Password"
                    onBlur={input.onBlur}
                    onChange={input.onChange}
                    onFocus={input.onFocus}
                    value={input.value}
                    error={meta.touched && meta.error}
                    type={passwordShown ? 'text' : 'password'}
                  />
                  <i onClick={togglePasswordVisiblity}>{passwordShown ? 'Hide' : 'Show'}</i>
                </div>
              )}
            </Field>
          </Col>
        </Row>
        <Row className={`${classes.rowWrapper} ${classes.margin}`}>
          <Col className={classes.col}>
            <Field name="birthdate" validate={required}>
              {({input, meta}) => (
                <div>
                  <Birthdate
                    name={input.name}
                    placeholder="Birthdate"
                    onBlur={input.onBlur}
                    onChange={input.onChange}
                    value={input.value}
                    error={meta.touched && meta.error}
                    className={classes.birthdate}
                  />
                </div>
              )}
            </Field>
          </Col>
          <Col className={classes.col}> 
            <Field name="country" validate={required}>
              {({input, meta}) => (
                <div>
                  <Countries
                    name={input.name}
                    placeholder="Country"
                    value={input.value}
                    onBlur={input.onBlur}
                    onChange={input.onChange}
                    onFocus={input.onFocus}
                    error={meta.touched && meta.error}
                    className={classes.countries}
                  />
                </div>
              )}
            </Field>
          </Col>
          <Col className={classes.col}>
            <Field name="zip_code" validate={composeValidators(required, zipcode)}>
              {({input, meta}) => (
                <div>
                  <TextInput
                    name={input.name}
                    placeholder="Zip Code"
                    onBlur={input.onBlur}
                    onChange={input.onChange}
                    onFocus={input.onFocus}
                    value={input.value}
                    error={meta.touched && meta.error}
                    className={classes.zipcode}
                  />
                </div>
              )}
            </Field>
          </Col>
        </Row>
        <Row>
          <Col lg="6" className="mx-auto">
            <Button type="submit" styling="large">
              {signupPending ? <Loader /> : 'Join'}
            </Button>
            <div className={classes.errorTxt} >{signupError}</div>
          </Col>
        </Row>
      </form>}
    </Form>
  );
}
