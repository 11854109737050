import React, { useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import * as _ from 'lodash';
import classNames from 'classnames/bind';
import classes from './HorseSelection.module.scss';
import CountdownTimer from '../Countdown';
import RaceCard from './RaceCard';
import RaceCardRow from './RaceCardRow';
import SortDropdown from './SortToggle';
import {useDateLabels} from '../DateLabels';
import RaceCardHeader from './RaceCardHeader';

const DateTab = ({ label, game }) => {
  const isActiveDate = label.startDate === game.race_date && label.endDate === game.race_date;
  const c = classNames.bind(classes);

  return (
    <Col xs={2} className={classNames(classes.tab, c({'active': isActiveDate}))} >
      <Link
        to={`/tracks/${label.startDate}/${label.endDate}`}>
        {label.name}
      </Link>
    </Col>
  );
};

const HorseSelection = ({
  gameObject,
  raceCardObject,
  userRunners,
  updateUserRunners,
  remainingSalary,
}) => {
  const [ sortBy, setSortBy ] = useState('Default');
  const sortOptions = ['Default', 'Salary'];
  const labels = useDateLabels();
  const raceCardObjRunners = _.get(raceCardObject, 'runners');
  const sortRunners = useCallback(
    () => {
      if (sortBy === 'Default') {
        return _.sortBy(raceCardObjRunners, ['post_position']);
      } else {
        return _.orderBy(raceCardObjRunners, 'salary', 'desc');
      }
    },
    [sortBy, raceCardObjRunners],
  );
  const runners = sortRunners();

  return (
    <div className={classes.selectionWrapper}>
      <Row className={classes.tabWrapper}>
        {labels.map((label) =>
          <DateTab
            key={label.id}
            label={label}
            game={gameObject}
          />,
        )}
      </Row>
      <div className={classes.raceCard}>
        <Row className={classes.trackHeader}>
          <Col className={classes.trackName} lg={3}>{_.get(raceCardObject, 'track.name')}:</Col>
          <Col className={classes.trackInfo} lg={3}>{_.get(gameObject,'name')}</Col>
          <Col className={classes.gameStart} sm={6} lg={3}>
            Game Starts
            <div className={classes.startTime}><CountdownTimer game={gameObject}/></div>
          </Col>
          <Col className={classes.sortWrapper} sm={6} lg={3}>
            Sort By: <SortDropdown sortOptions={sortOptions} sortBy={sortBy} setSortBy={setSortBy} />
          </Col>
        </Row>
        <Row className={classes.raceWrapper}>
          <Col md={12}>
            {sortBy === 'Salary' && <RaceCardHeader />}
            {sortBy === 'Default' ? raceCardObject.races.map((race, index) => (
              <RaceCard index={index + 1}
                key={`race${index}`}
                runners={runners.filter((runner) => runner.race_number === (raceCardObject.races.indexOf(race) + 1))}
                userRunners={userRunners}
                race={race}
                onUpdateRunners={updateUserRunners}
                remainingSalary={remainingSalary}
                runnerLimit={gameObject.runner_limit}
              />
            )):(
              runners.map((runner) => <RaceCardRow
                key={`runner${runner.id}`}
                runner={runner}
                updateRunners={updateUserRunners}
                userRunner={userRunners.filter((userRunner) => userRunner.id === runner.id)}
                disabled={runner.salary > remainingSalary || userRunners.length >= _.get(gameObject,'runner_limit')}
              />,
              ))
            }
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default HorseSelection;
