import React from 'react';

import classes from './WelcomeScreen.module.scss';
import LoginForm from '../components/forms/LoginForm';
import LinkBtn from './LinkBtn';
import Background from './Background';

export default function Login () {

  return (
    <div className={`${classes.defaultBackground} container`} id="login-page">
      <Background />
      <div className={classes.pagecontent}>
        <div className={classes.logoWrapper} />
        <div className={classes.formWrapper}>
          <LoginForm />
        </div>
        <div className={classes.switchWrapper}>
          <div className={classes.joinTxt}>New to StableDuel? Sign up now</div>
          <LinkBtn className="mt-5" label="Join" path="/signup" styling="btnLink" />
        </div>
      </div>
    </div>
  );
}
