import React from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import _ from 'lodash';
import classes from './TrackFilter.module.scss';
import TrackFilterItem from './TrackFilterItem';

import { useDateLabels } from './DateLabels';

export default function TrackFilter () {
  const dateTabs = useDateLabels();

  const dateList = _.map(
    dateTabs,
    (tab) => (
      <TrackFilterItem
        key={tab.id}
        link={`/tracks/${tab.startDate}/${tab.endDate}`}
        startDate={tab.startDate}
        endDate={tab.endDate}
        setActiveClass
        races={tab.races}
      >
        {tab.name}
      </TrackFilterItem>
    ),
  );

  return (
    <Navbar
      className={classes.trackNav}
      variant="dark"
    >
      <Nav className="mr-auto">
        {dateList}
      </Nav>
    </Navbar>
  );
}
