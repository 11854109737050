import React from 'react';
import { useSelector } from 'react-redux';
import * as _ from 'lodash';
import {
  Col,
  Row,
  Container,
} from 'react-bootstrap';
import LinkBtn from '../components/LinkBtn';
import classes from './Profile.module.scss';
import TrophyProgress from '../components/TrophyProgress.js';
import ProgressBar from '../components/ProgressBar.js';
import RacetrackProgressBar from '../components/RacetrackProgress.js';
import { findPlayerLevel } from '../shared/StablePointTiers';
import SDLogoWithTag from '../assets/images/stableduel-logo-with-tag.png';
import ProfileSummary from '../components/myProfile/ProfileSummary.js';
import { getUser } from '../redux/selectors/user';

const Profile = () => {
  const user = useSelector(getUser);
  const stablePoints = _.get(user, 'stable_points');
  const playerLevel = findPlayerLevel(stablePoints === undefined ? 0 : stablePoints);

  return (
    <Container fluid className={classes.myProfile}>
      <Row>
        <Col className={classes.contentCell} lg>
          <Row className={classes.profile}>
            <ProfileSummary />
          </Row>
          <Row>
            MY FINISHES
          </Row>
        </Col>
        <Col className={classes.contentNoCell} lg>
          <Row className={classes.trackTrophy}>
            <Col xs={{span: 6, offset: 1}}>
              <RacetrackProgressBar userProgress={playerLevel.distanceToLevel} />
            </Col>
            <Col xs={3}>
              <TrophyProgress img={playerLevel.src} />
            </Col>
          </Row>
          <Row className={classes.progressBar}>
            <ProgressBar playerLevel={playerLevel} />
          </Row>
          <Row className={classes.sdLogoRow}>
            <img
              className={classes.sdLogoTag}
              src={SDLogoWithTag}
              alt="Stable Duel: Play Race Win"
            />
          </Row>
          <Row className={`${classes.newGameBtn} justify-content-md-center`}>
            <Col xl="6">
              <LinkBtn
                label='+ NEW GAME'
                path='/tracks'
                styling='outline-myProfile'
              />
            </Col>
          </Row>
        </Col>
        <Col className={classes.contentCell} lg>
          <Row>
            Past Performance
          </Row>
          <Row>
            My Follows
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default Profile;
