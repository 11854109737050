import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector  } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroller';
import * as _ from 'lodash';
import {
  Container,
  Row,
  Col,
} from 'react-bootstrap';

import classes from './GlobalLeaderBoard.module.scss';
import MyStablesImage from '../../assets/images/nav_mystables.png';
import { fetchGlobalLeaderBoard, clearStables } from '../../redux/actions/globalLeaderBoard';
import { getUser } from '../../redux/selectors/user';

const GlobalLeaderBoard = () => {
  const dispatch = useDispatch();
  const { isLoading, hasMoreItems, stables } = useSelector((state) => ({
    isLoading: state.globalLeaderBoard.isLoading,
    hasMoreItems: state.globalLeaderBoard.hasMoreItems,
    stables: state.globalLeaderBoard.stables,
  }));

  const user = useSelector(getUser);
  const rank = _.get(user, 'rank');
  const stableName = _.get(user, 'profile.stable_name');
  const stablePoints = _.get(user, 'stable_points');

  const loadItems = useCallback(
    (page) => {
      dispatch(fetchGlobalLeaderBoard(page));
    },[dispatch],
  );

  useEffect(()=> {
    return () => {
      dispatch(clearStables());
    };
  },[dispatch]);

  const Leaderboard = ({ payload = {} }) => {

    return (
      <Row className={`${classes.userRow} ${(payload.rank === rank) && classes.myStable} mx-0`}>
        <Col sm={2} className={`
          ${classes.rank} ${(payload.rank === rank) && classes.topThree}
        `}>
          {payload.rank}
        </Col>
        <Col sm={5} className={`${classes.stableName} `}>
          {payload.stable_name}
        </Col>
        <Col sm={{ span: 3, offset: 1 }} className={`${classes.boardPoints} `}>
          {(payload.score)}
        </Col>
      </Row>
    );
  };

  return (
    <Container className={classes.leaderboard}>
      <Row className={`${classes.widgetTitle} justify-content-sm-center`}>ANNUAL LEADERBOARD</Row>
      <Row className={`${classes.myStableInfo} pt-3 mx-2`}>
        <Col sm={2} className={`${classes.myRank} `}>{rank}</Col>
        <Col sm={2}>
          <img className={`${classes.image}`} src={MyStablesImage} alt="horse"></img>
        </Col>
        <Col sm={4} className={`${classes.myStableName} text-center px-1`}>
          {stableName}
        </Col>
        <Col sm={4} className={`${classes.myPoints}`}>{stablePoints}</Col>
      </Row>
      <Row className={`${classes.tableHeadings} mx-2 mt-1`}>
        <Col sm={2} className="pr-1 pl-4">Rank</Col>
        <Col sm={2}>Stable</Col>
        <Col sm={{span: 4, offset: 4}}>Total Points</Col>
      </Row>
      <Row className={`${classes.boardObjects} mx-2`}>
        <InfiniteScroll
          pageStart={0}
          loadMore={loadItems}
          hasMore={!isLoading && hasMoreItems}
          loader={<div key={0}>Loading ...</div>}
          useWindow={false}
          style={{width: '100%', height: '18.75rem'}}
        >
          {_.map(
            stables,
            (stable) => <Leaderboard key={stable.id} payload={stable} />,
          )}
        </InfiniteScroll>
      </Row>
    </Container>
  );
};
export default GlobalLeaderBoard;
