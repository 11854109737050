import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import _ from 'lodash';
import moment from 'moment';
import Button from './Button.js';
import classes from './GameDetails.module.scss';
import CountdownTimer from './Countdown.js';
import RaceCardModal from './modals/RaceCardModal';
import EnterGameBtn from './common/EnterGameBtn';
import { getTimeUntilFirstRace } from '../shared/gameUtils';

const GameDetails = ({
  game,
  dateCol,
}) => {
  const [modalShow, setModalShow] = useState(false);
  const timeUntilStart = getTimeUntilFirstRace(game);
  const gameName = _.get(game,'name', '');
  const activeStables = _.get(game,'stable_count', 0);
  const gameDate = _.get(game, 'racecard_obj.race_date', '');
  const today = moment().format('YYYY-MM-DD');
  let gameDateDisplay = '';
  if (gameDate && dateCol === 'show') {
    if (gameDate > today) {
      gameDateDisplay = <p className={classes.text}>{moment(gameDate).format('ddd').toUpperCase()
        + '. ' + moment(gameDate).format('MMM').toUpperCase() + '.'}<br />
      <span className={classes.largeNumber}>{moment(gameDate).format('D')}</span> </p>;
    } else {
      gameDateDisplay = <p className={classes.largeGreenText}>TODAY</p>;
    }
  }

  let currentPool=game.pool;

  if (currentPool > 0) {
    currentPool = currentPool.toLocaleString(navigator.language, { minimumFractionDigits: 0 });
  }

  return (
    <Row className={classes.row}>
      <Col lg={3}>
        <p className={classes.title}>{gameName || 'Game Name'}</p>
      </Col>
      <Col lg={1} className={classes.col}>{gameDateDisplay}</Col>
      <Col lg={2} className={classes.col}>
        { timeUntilStart > 0 &&
          <p className={classes.boldText}>GAME STARTS<br />
            <span className={classes.gameStart}>
              <CountdownTimer game={game} />
            </span>
          </p> }
      </Col>
      <Col lg={2} className={classes.enterGameBtn}>
        <EnterGameBtn game={game} fromTracks={true}/>
      </Col>
      <Col lg={1} className={classes.col}>
        <p className={classes.text}>ACTIVE STABLES <br />
          <span className={classes.largeNumber}>{activeStables}</span> </p>
      </Col>
      <Col lg={1} className={classes.col}>
        <p className={classes.text}>CURRENT <br />
          <span className={classes.largeNumber}>${currentPool}</span></p>
      </Col>
      <Col lg={2} className={classes.col}>
        <Button
          styling={'whiteOutlineSkinny'}
          clickAction={() => setModalShow(true)}
          disabled={!_.has(game, 'racecard_obj.races', false)}>
            RACE CARD
        </Button>
        <RaceCardModal
          show={modalShow}
          onHide={() => setModalShow(false)}
          races={_.get(game, 'racecard_obj.races')}
          gameName={gameName}
          gameDate={gameDate}
        />
      </Col>
    </Row>
  );
};

export default GameDetails;
