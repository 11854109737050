import React from 'react';
import { useSelector } from 'react-redux';
import classes from './BuildStableCalc.module.scss';
import {
  Container,
  Row,
  Col,
} from 'react-bootstrap';
import {
  remainingSalary,
  numberOfRunnersInStable,
  numberOfRunnerLimit,
  averageSalaryPerHorse,
  remainingSalaryPerHorse,
  isValid,
} from '../../redux/selectors/stableBuilder';

export default function StableCalculation () {
  const remaining = useSelector(remainingSalary);
  const runnerCount = useSelector(numberOfRunnersInStable);
  const runnerLimit = useSelector(numberOfRunnerLimit);
  const avgPerHorse = useSelector(averageSalaryPerHorse);
  const salaryPerHorse = useSelector(remainingSalaryPerHorse);
  const valid = useSelector(isValid);

  return (
    <Container className={`${classes.myStableInfo}`}>
      <Row className="align-items-center">
        <Col sm={3}>
          <Row className="justify-content-center">
            {runnerCount} / {runnerLimit}
          </Row>
          <Row className={classes.bottomLabel}>
            SELECTED
          </Row>
        </Col>
        <Col sm={5}>
          <Row className={classes.salaryRemaining}>
            ${remaining.toLocaleString()}
          </Row>
          <Row className={`${classes.bottomLabel} pb-3`}>
            Salary Remaining
          </Row>
        </Col>
        <Col sm={4}>
          <Row className="justify-content-center">
            ${valid ?
              avgPerHorse.toLocaleString('en-us', { maximumFractionDigits: 0 }) :
              salaryPerHorse.toLocaleString('en-us', { maximumFractionDigits: 0 })}
          </Row>
          <Row className={classes.bottomLabel}>
            {valid ? 'AVG/HORSE' : 'REM/HORSE'}
          </Row>
        </Col>
      </Row>
    </Container>
  );
}
