import React, { useState, useEffect } from 'react';
import classes from './RacetrackProgress.module.scss';

const RacetrackProgressBar = ({ userProgress = 0 }) => {
  const finalProgress = Math.min(userProgress, 100);
  const totalPathLength = 100;
  const frames= 25;
  const increment = finalProgress/frames;
  const [ progress, setProgress ] = useState(0);
  const finalDashOffset = totalPathLength - progress;

  useEffect(() => {
    let timer;
    if (progress < finalProgress) {
      timer = setTimeout(() => {
        setProgress( progress + increment );
      }, 100);
    }

    return function cleanup () {
      clearTimeout(timer);
    };
  });

  return (
    <svg width="100%" viewBox="0 0 400 160" preserveAspectRatio="none">
      <g className={classes.trackBackground}>
        <path id="trackBackground"
          strokeWidth="25"
          d="M 300 150 L 300 150 C 400 150 400 10 300 10 L 100 10 C 0 10 0 150 100 150 Z"
          pathLength="100"
        />
      </g>
      <g className={classes.trackProgress}>
        <path id="trackProgress" className={classes.pathTrack}
          strokeWidth="25"
          style={{
            strokeDasharray: totalPathLength,
            strokeDashoffset: finalDashOffset,
          }}
          d="M 200 150 L 300 150 C 400 150 400 10 300 10 L 100 10 C 0 10 0 150 100 150 Z"
          pathLength="100"
        />
      </g>
      {finalProgress < 100 ?
        <g className={classes.chartText}>
          <text x="50%" y="40%" className={classes.chartNumber}>
            {finalProgress}%
          </text>
          <text x="50%" y="40%" className={classes.chartLabel}>
            Next Level
          </text>
        </g>
        : ''}
    </svg>
  );
};

export default RacetrackProgressBar;
