import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  Row,
  Col,
  Container,
} from 'react-bootstrap';
import * as _ from 'lodash';
import classes from './EditStableContainer.module.scss';
import StableCalculation from './BuildStableCalc';
import RunnersList from './RunnersList';
import BuildStablesImage from '../../assets/images/horse-green-small.png';
import { submitStable, deleteStable } from '../../redux/actions/stableBuilder';
import DeleteStableModal from '../modals/DeleteStableModal';
import {
  isEditable,
  isValid,
  getGame,
  getStableSubmitting,
} from '../../redux/selectors/stableBuilder';
import { getEntryFee } from '../../shared/gameUtils';
import Button from '../Button.js';
import Loader from '../Loader';

const EditStableContainer = ({
  stableId,
  userRunners,
  updateUserRunners,
  onEnablePrompt,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const isValidStable = useSelector(isValid);
  const isStableEditable = useSelector(isEditable);
  const game = useSelector(getGame);
  const stableSubmitting = useSelector(getStableSubmitting);
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const fee = getEntryFee(game);

  const onSubmitStable = () => {
    onEnablePrompt(false);
    const params = {
      id: stableId,
      history,
    };
    dispatch(submitStable(params));
  };

  const onDeleteStable = () => {
    onEnablePrompt(false);
    const params = {
      id: stableId,
      history,
    };
    dispatch(deleteStable(params));
  };

  return (
    <Container className={classes.editStableContainer}>
      <Row className={classes.title}>
        <img className={`${classes.image}`} src={BuildStablesImage} alt="horse"></img>
        BUILD YOUR STABLE
      </Row>
      <RunnersList
        userRunners={_.orderBy(userRunners, ['race_number','post_position'], ['asc', 'asc'])}
        onUpdateRunners={updateUserRunners}
      />
      <Row className={`${classes.buttonsRow} flex-nowrap mt-3`}>
        <Col sm={3} className="px-1">
          <div className={`${classes.deleteButton} mx-auto`}
            onClick={() => setDeleteModalShow(true)}
          />
        </Col>
        <Col sm={9}className="pl-0">
          {isStableEditable && isValidStable ?
            <Row className='justify-content-lg-center mt-4'>
              <Button styling={'greenSkinny'} clickAction={() => onSubmitStable()}>
                {stableSubmitting ? <Loader /> : `SUBMIT STABLE - ${fee}` }
              </Button>
            </Row> :
            <StableCalculation />}
        </Col>
      </Row>
      {deleteModalShow &&
        <DeleteStableModal
          show={deleteModalShow}
          onHide={() => setDeleteModalShow(false)}
          onDeleteStable={() => onDeleteStable()}
        />}
    </Container>
  );
};

export default EditStableContainer;
