import {
  FETCH_RACECARD_BY_ID,
  FETCH_SINGLE_RACECARD_DATA_SUCCESS,
  FETCH_RACECARD_DATA_FAIL,
} from '../actions/racecards';

import * as _ from 'lodash';

const initialState = {
  data: {},
  loading: false,
  loadingCount: 0,
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_RACECARD_BY_ID:
      return {
        ...state,
        loading: true,
        loadingCount: state.loadingCount + 1,
      };
    case FETCH_SINGLE_RACECARD_DATA_SUCCESS:
      return {
        ...state,
        data: _.merge({}, state.data, { [action.data.id]: { ...action.data } }),
        loadingCount: state.loadingCount - 1,
        loading: state.loadingCount > 1,
      };
    case FETCH_RACECARD_DATA_FAIL:
      return {
        ...state,
        loadingCount: state.loadingCount - 1,
        loading: state.loadingCount > 1,
        error: action.error,
      };
    default:
      return state;
  }
};
