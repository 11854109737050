import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import {
  Row,
  Col,
  Container,
} from 'react-bootstrap';
import * as _ from 'lodash';
import classes from './StableBuilder.module.scss';
import HorseSelection from '../../components/StableBuilder/HorseSelection';
import EditStableContainer from '../../components/StableBuilder/EditStableContainer';
import Loader from '../../components/Loader';
import LeaveStableBuildingModal from '../../components/modals/LeaveStableBuildingModal';
import usePrompt from '../../components/customHooks/Prompt';
import {
  remainingSalary,
  getGame,
  getRunnersWithInfo } from '../../redux/selectors/stableBuilder';
import {
  submitStable,
  editStable,
  updateRunners } from '../../redux/actions/stableBuilder';

const StableBuilder = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { mode, stableId } = useParams();

  const stableLoading = useSelector((state) => state.stableBuilder.loading);
  const gamesLoading = useSelector((state) => state.games.loading);
  const racecardsLoading = useSelector((state) => state.racecards.loading);
  const isLoading = gamesLoading || racecardsLoading || stableLoading;

  const game = useSelector(getGame);
  const userRunnersWithInfo = useSelector(getRunnersWithInfo);
  const remainingMoney = useSelector(remainingSalary);
  const [isPromtEnable, setIsPromptEnable] = useState(true);
  const [showPrompt, confirmNavigation, cancelNavigation] = usePrompt(isPromtEnable);

  useEffect (() => {
    dispatch(editStable({
      id: stableId,
      mode,
      history,
    }));
  }, [dispatch, mode, stableId, history]);

  const onSave = () => {
    dispatch(submitStable({
      id: stableId,
    }));
    confirmNavigation();
  };

  const onEnablePrompt = (show) => {
    setIsPromptEnable(show);
  };

  const onUpdateUserRunners = (runner) => {
    let updatedRunners = [];
    const runnerIds = _.map(userRunnersWithInfo, 'id');
    if (_.find(runnerIds, (id) => id === runner.id)) {
      updatedRunners = _.filter(runnerIds, (id) => id !== runner.id);
    } else {
      updatedRunners = [...runnerIds, runner.id];
    }
    const params = {
      id: stableId,
      runners: updatedRunners,
    };
    dispatch(updateRunners(params));
  };

  return (
    <div className={'App-content'}>
      <LeaveStableBuildingModal
        onSave={onSave}
        showPrompt={showPrompt}
        cancelNavigation={cancelNavigation}/>
      {isLoading ?
        <Loader /> :
        <Container fluid>
          { game && game.racecard_obj && userRunnersWithInfo &&
            <Row className={classes.contentColumn}>
              <Col className={classes.contentCell} md={8}>
                <HorseSelection
                  gameObject={game}
                  raceCardObject={_.get(game, 'racecard_obj')}
                  updateUserRunners={onUpdateUserRunners}
                  userRunners={userRunnersWithInfo}
                  remainingSalary={remainingMoney}
                />
              </Col>
              <Col className={classes.contentCell} md={4}>
                <EditStableContainer
                  stableId={stableId}
                  updateUserRunners={onUpdateUserRunners}
                  userRunners={userRunnersWithInfo}
                  onEnablePrompt={onEnablePrompt}
                />
              </Col>
            </Row>
          }
        </Container>
      }
    </div>
  );
};
export default StableBuilder;
