import * as Actions from '../actions/games';
import { responseArrayToObject } from './utils';
import * as _ from 'lodash';

const initialState = {
  data: {},
  loading: false,
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case Actions.FETCH_GAMES_BY_DATES:
      return {
        ...state,
        loading: true,
      };
    case Actions.FETCH_GAMES_DATA_MULTIPLE_SUCCESS:
      return {
        ...state,
        data: _.merge({}, state.data, responseArrayToObject(action.data)),
        loading: false,
      };
    case Actions.FETCH_GAME_DATA_SINGLE_SUCCESS:
      return {
        ...state,
        data: _.merge({}, state.data, { [action.data.id]: { ...action.data } }),
        loading: false,
      };
    case Actions.FETCH_GAMES_DATA_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};
