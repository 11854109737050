export const FETCH_RACECARD_BY_ID = 'RACECARD/FETCH_BY_ID';
export const FETCH_SINGLE_RACECARD_DATA_SUCCESS = 'RACECARD/FETCH_SINGLE_DATA_SUCCESS';
export const FETCH_RACECARD_DATA_FAIL = 'RACECARD/FETCH_DATA_FAIL';

export const fetchRacecardById = (id) => ({
  type: FETCH_RACECARD_BY_ID,
  id,
});

export const fetchSingleRacecardSuccess = (data) => ({
  type: FETCH_SINGLE_RACECARD_DATA_SUCCESS,
  data,
});

export const fetchRacecardFail = (error) => ({
  type: FETCH_RACECARD_DATA_FAIL,
  error,
});
