import { put, takeLatest } from 'redux-saga/effects';
import { getTracks } from '../../api/TracksApi';
import * as Actions from '../actions/tracks';

export function* fetchTracks () {
  try {
    const response = yield getTracks();
    yield put(Actions.fetchTracksSuccess(response));
  } catch (error) {
    yield put(Actions.fetchTracksFail(error.response.data));
  }
}

export default function* tracksSaga () {
  yield takeLatest(Actions.FETCH_TRACKS_DATA, fetchTracks);
}
