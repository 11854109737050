import { sdApiRequest, PAGE_SIZE } from './StableDuelApi.js';

export const fetchGlobalLeaderBoard = (page = 1, page_size = PAGE_SIZE) => {
  const params = {
    page,
    page_size,
  };

  return sdApiRequest({
    method: 'GET',
    url: 'global-leaderboard/',
    data: params,
    authenticationRequired: false,
  });
};
