import React from 'react';

import classes from './Background.module.scss';

const Background = () => {
  const videoSource =
    'https://s3.amazonaws.com/splash.stableduel.com/media/193547-StableDuel-BackgroundVIdeos-IHP-Main-WIDE-720.mp4';

  return (
    <div className={classes.wrapper}>
      <video
        id="video"
        autoPlay
        loop
        muted
        playsInline
        className={classes.video}
      >
        <source src={videoSource} type="video/mp4" />
      </video>
      <div className={classes.shadow}></div>
    </div>
  );
};

export default Background;
