import React from 'react';
import { useSelector } from 'react-redux';
import * as _ from 'lodash';
import {
  Container,
  Row,
  Col,
} from 'react-bootstrap';
import classes from './ProfileSummary.module.scss';
import { getUser } from '../../redux/selectors/user';
import { thousandsSeparator } from '../../shared/utility';

const ProfileSummary = () => {
  const user = useSelector(getUser);
  const rank = _.get(user, 'rank', 0);
  const stableName = _.get(user, 'profile.stable_name');
  const stablePoints = _.get(user, 'stable_points', 0);
  const contestsEntered = _.get(user, 'contests_entered');
  // TODO
  // const followers = _.get(user, 'followers');

  return (
    <Container className={classes.profileSummary}>
      <Row>
        <Col xs={4}>
          <div className={classes.addImage}>
            <div className={classes.addStatus} />
          </div>
        </Col>
        <Col xs={8}>
          <Row className={classes.top}>
            <div className={classes.stableName}>
              {stableName}
            </div>
          </Row>
          <Row>
            <div className={classes.followers}>
              {/* TODO */}
              {/* {followers && thousandsSeparator(followers)} Followers */}
            </div>
          </Row>

        </Col>
      </Row>
      <Row>
        <Col xs={3}>
          <div className={classes.numbers}>{rank}</div>
          <div className={classes.text}>Rank</div>
        </Col>
        <Col xs={5}>
          <div className={classes.numberPoints}>
            {stablePoints && thousandsSeparator(stablePoints)}
          </div>
          <div className={classes.text}>StableDuel Points</div>
        </Col>
        <Col xs={3}>
          {contestsEntered && <div className={classes.numbers}>{contestsEntered}</div>}
          {contestsEntered && <div className={classes.text}>Contests</div>}
        </Col>
      </Row>
    </Container>
  );
};

export default ProfileSummary;
