import { sdApiRequest } from './StableDuelApi.js';

export const fetchUser = (token) => {
  return sdApiRequest({
    method: 'GET',
    url: 'me/',
    token,
  });
};

export const createNewUser = (user) => {
  return sdApiRequest({
    method: 'POST',
    url: 'signup/',
    data: user,
  });
};

export const validateBirthday = (zip, birthday) => {
  return sdApiRequest({
    method: 'GET',
    url: 'profile/birthday/validate',
    data: {
      zip,
      birthday,
    },
  });
};
