export const FETCH_LEADERBOARD = 'LEADERBOARD/FETCH';
export const FETCH_LEADERBOARD_SUCCESS = 'LEADERBOARD/FETCH_SUCCESS';
export const FETCH_LEADERBOARD_FAIL = 'LEADERBOARD/FETCH_FAIL';
export const CLEAR_LEADERBOARD = 'LEADERBOARD/CLEAR';

export const fetchLeaderBoard = ({gameId, page}) => ({
  type: FETCH_LEADERBOARD,
  gameId,
  page,
});

export const fetchLeaderBoardSuccess = (data) => ({
  type: FETCH_LEADERBOARD_SUCCESS,
  data,
});

export const fetchLeaderBoardFail = (error) => ({
  type: FETCH_LEADERBOARD_FAIL,
  error,
});

export const clearLeaderBoard = () => ({
  type: CLEAR_LEADERBOARD,
});
