import { put, call, select, takeEvery } from 'redux-saga/effects';
import { getLeaderboard } from '../../api/StablesApi';
import { getToken } from '../selectors/account';
import {
  FETCH_LEADERBOARD,
  fetchLeaderBoardSuccess,
  fetchLeaderBoardFail,
} from '../actions/leaderBoard';

function* fetchLeaderBoard ({ gameId, page }) {
  try {
    const token = yield select(getToken);
    const response = yield call(getLeaderboard, gameId, token, page);
    yield put(fetchLeaderBoardSuccess(response));
  } catch (error) {
    yield put(fetchLeaderBoardFail(error));
  }
}

export default function* leaderBoardSaga () {
  yield takeEvery(FETCH_LEADERBOARD, fetchLeaderBoard);
}
