import React from 'react';
import { useSelector } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import { Modal } from 'react-bootstrap';
import Button from '../Button.js';
import classes from './LeaveStableBuildingModal.module.scss';
import Loader from '../Loader';

function LeaveStableBuildingModal ({ onSave, showPrompt, cancelNavigation}) {
  const isSubmitting = useSelector((state) => state.stableBuilder.submitting);

  return (
    <Modal
      show={showPrompt}
      onHide={cancelNavigation}
      centered
    >
      <Modal.Header
        className={classes.leaveHeader}
        closeButton
      >
      </Modal.Header>
      <Modal.Body
        className={classes.leaveBody}
      >
        <p> Are you sure you want to exit the stable building process?</p>
        <Row>
          <Col>
            <Button
              styling={'redOutline'}
              clickAction={() => {
                onSave();
              }}>
              {isSubmitting ? <Loader /> : 'YES - SAVE & EXIT'}</Button>
          </Col>
          <Col>
            <Button
              styling={'black'}
              clickAction={cancelNavigation}>NO - CANCEL</Button>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
}

export default LeaveStableBuildingModal;
