import React from 'react';
import classes from './TrophyProgress.module.scss';

const TrophyProgress = ({ img }) => {
  return <img className={classes.trophy} src={img} alt="Trophy" />;
};

TrophyProgress.defaultProps = {
  stablePoints: 0,
};

export default TrophyProgress;
