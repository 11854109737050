import React from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import classes from './NavigationBar.module.scss';
import NavigationItem from './NavigationItem';
import stableDuelLogo from '../assets/images/stableduel_logo_horizontal.png';
import LobbyImage from '../assets/images/nav_lobby.png';
import MyStablesImage from '../assets/images/nav_mystables.png';
import TracksImage from '../assets/images/nav_tracks.png';
import NotificationImage from '../assets/images/notifications-default.png';
import UserImage from '../assets/images/myaccount-default.png';
import ProfileImage from '../assets/images/myprofile-silks.png';

const NavigationBar = () => {

  return (
    <Navbar data-testid="navbar" expand="md" fixed="top" variant="dark" className={classes.SDnavbar}>
      <Navbar.Brand href="/lobby">
        <img className={classes.navLogo} src={stableDuelLogo} alt="StableDuel" />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mr-auto">
          <NavigationItem
            link="/lobby"
            exact image={LobbyImage}
            width={25}
            height={15}
            setActiveClass
          >
            LOBBY
          </NavigationItem>
          <NavigationItem
            link="/tracks"
            image={TracksImage}
            width={28}
            height={15}
            setActiveClass
          >
            TRACKS
          </NavigationItem>
          <NavigationItem
            link="/stables"
            image={MyStablesImage}
            width={28}
            height={15}
            setActiveClass
          >
            MY STABLES
          </NavigationItem>
          <NavigationItem
            link="/profile"
            exact image={ProfileImage}
            width={20}
            height={15}
            setActiveClass
          >
            MY PROFILE
          </NavigationItem>
        </Nav>
        <Nav className="ml-auto">
          <NavigationItem
            link="/notifications"
            exact image={NotificationImage}
            width={20}
            height={20}
          >
          </NavigationItem>
          <NavigationItem
            link="/settings"
            exact image={UserImage}
            width={20}
            height={20}
            setActiveClass
          >
          </NavigationItem>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default NavigationBar;
