import { sdApiRequest, PAGE_SIZE } from './StableDuelApi.js';

export const getStablesByUserId = (userId, token, page = 1, page_size = PAGE_SIZE) => {
  const params = {
    page,
    page_size,
  };

  return sdApiRequest({
    method: 'GET',
    url: `stables/?user=${userId}`,
    data: params,
    token,
  });
};

export const getStablesForGame = (userId, gameId, token) => {
  const params = {
    user: userId,
    game: gameId,
  };

  return sdApiRequest({
    method: 'GET',
    url: 'stables/',
    data: params,
    token,
  });
};

export const createStableForUser = (stable, token) => {
  return sdApiRequest({
    method: 'POST',
    url: 'stables/',
    data: stable,
    token,
  });
};

export const updateStableForUser = (stableId, stable, token) => {
  return sdApiRequest({
    method: 'PUT',
    url: `stables/${stableId}/`,
    data: stable,
    token,
  });
};

export const getStableById = (stableId, token) => {
  return sdApiRequest({
    method: 'GET',
    url: `stables/${stableId}`,
    token,
  });
};

export const deleteStableById = (stableId, token) => {
  return sdApiRequest({
    method: 'DELETE',
    url: `stables/${stableId}`,
    token,
  });
};

export const getLeaderboard = (game, token, page = 1, page_size=PAGE_SIZE) => {
  const params = {
    game,
    page,
    page_size,
  };

  return sdApiRequest({
    method: 'GET',
    url: 'leaderboard/',
    data: params,
    token,
  });
};
