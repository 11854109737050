import { sdApiRequest } from './StableDuelApi.js';

export const getTracks = () => {
  return sdApiRequest({
    method: 'GET',
    url: 'tracks/',
  });
};

export const getTrackById = (id) => {
  return sdApiRequest({
    method: 'GET',
    url: `tracks/${id}/`,
  });
};
