import * as Actions from '../actions/globalLeaderBoard';

const initialState = {
  stables: [],
  isLoading: false,
  error: null,
  hasMoreItems: true,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case Actions.FETCH_GLOBAL_LEADER_BOARD:
      return {
        ...state,
        isLoading: true,
      };
    case Actions.FETCH_GLOBAL_LEADER_BOARD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        stables: [...state.stables, ...action.data.results],
        hasMoreItems: action.data.next !== null,
      };
    case Actions.FETCH_GLOBAL_LEADER_BOARD_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    case Actions.CLEAR_STABLES:
      return {
        ...state,
        stables: [],
      };
    default: return state;
  }
};
